import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AgendaDetalhes } from "../../../agenda/shared/models";
import { RelatorioAulaService } from "../../../agenda/shared/services";
import { ListComponent } from "../../../shared/components";
import { PermissaoService } from "../service/permissao.service";

@Component({
  selector: 'app-perfil-aulas',
  templateUrl: './perfil-aulas.component.html',
})
export class PerfilAulasComponent extends ListComponent<AgendaDetalhes> implements OnInit, OnDestroy {
  public static resourceName = 'RelatorioAula';
  public customSearchForm: UntypedFormGroup;


  constructor(
    protected injector: Injector,
    protected servive: RelatorioAulaService,
    private permissionService: PermissaoService,
  ) {
    super(injector, servive);
  }

  ngOnInit() {
    this.baseListConfig.title = 'MINHAS AULAS';
    this.baseListConfig.icon = 'fa fa-calendar-o';

    this.initCustomFormControls();

    this.customSearchForm.controls.professionalId.setValue(this.permissionService.idUsuarioLogado());

    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public loadList() {
    if (this.customSearchForm.invalid) {
      return;
    }

    const formValues = this.customSearchForm.getRawValue();
    const endpoint = 'buscar-aulas/' + (new Date(formValues.dataInicio)).toDateString() + '/' + (new Date(formValues.dataFim)).toDateString() +
      '/funcionario/' + formValues.professionalId;

    console.log('endpoint novo', endpoint);
    super.loadList(endpoint);
  }

  private initCustomFormControls() {
    this.customSearchForm = new UntypedFormBuilder().group({
      dataInicio: [],
      dataFim: [],
      professionalId: [1755, [Validators.required, Validators.min(1)]]
    });

    const today = new Date(),
      curYear = today.getFullYear(),
      curMonth = today.getMonth();

    this.customSearchForm.controls.dataInicio.setValue(new Date(curYear, curMonth, 1));
    this.customSearchForm.controls.dataFim.setValue(new Date(curYear, curMonth + 1, 0));
  }

}
