import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { CoreService } from '../../../shared/core.service';
import { ContaPagar } from '../models';

@Injectable()
export class ContaPagarService extends CoreService<ContaPagar> {
    protected url: string = this.api.url + 'contaspagar/';

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    public save(entity: ContaPagar): Observable<ContaPagar> {
        const dataPagamento = entity.processarPagamento ? new Date(entity.dataPagamento).toDateString() : null,
              dataVencimento = new Date(entity.dataVencimento).toDateString(),
              newItem = Object.assign(entity);

        newItem.dataPagamento = dataPagamento;
        newItem.dataVencimento = dataVencimento;

        return super.save(newItem);
    }
}
