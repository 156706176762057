import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Endereco } from './models';
import { AppSession } from '../auth/shared/models';

import { ToastrService } from 'ngx-toastr';

export abstract class CoreService<T extends IEntity> {
    protected http: HttpClient;
    protected router: Router;

    protected api = environment.api.azure;
    protected url: string;

    private _curItem: BehaviorSubject<T> = new BehaviorSubject<T>(null);

    private _action: BehaviorSubject<string> = new BehaviorSubject(null);
    public action: Observable<string> = this._action.asObservable();

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
        this.router = injector.get(Router);
    }

    public get(endpoint?: string) {
        const url = endpoint ? this.url + endpoint : this.url;

        return this.http.get<T>(url, this.getHeaders())
            .pipe(
                map(data => {
                    this.setCurItem(data);
                    return data;
                }),
                catchError(this.handleError)
            );
    }

    public getList(endpoint?: string): Observable<T[]> {
        const url = endpoint ? this.url + endpoint : this.url;

        return this.http.get<T[]>(url, this.getHeaders())
            .pipe(
                map(data => data),
                catchError(this.handleError)
            );
    }

    public save(entity: T) {
        if (entity.id) {
            return this.put(entity);
        } else {
            return this.post(entity);
        }
    }

    public inativarUsuario(username: string): Observable<T> {
        const endpoint = `inativar/usuario?email=${username}`;
        this.router.navigate(['/', 'auth', 'logout']);
        return this.http.put<T>(this.url + endpoint, null, this.getHeaders())
          .pipe(
            map(data => {
              this.setCurItem(data);
              this.setAction('update');
              // Navega para a rota de logout após a inativação
              return data;
            }),
            catchError(this.handleError)
          );
      }

    public post(entity: T, endpoint?: string): Observable<T> {
        const url = endpoint ? this.url + endpoint : this.url;

        return this.http.post<T>(url, entity, this.getHeaders())
            .pipe(
                map(data => {
                    this.setCurItem(data);
                    this.setAction('update');

                    return data;
                }),
                catchError(this.handleError)
            );
    }

    public put(entity: T, endpoint?: string): Observable<T> {
        const url = endpoint ? this.url + endpoint : this.url;

        return this.http.put<T>(url, entity, this.getHeaders())
            .pipe(
                map(data => {
                    this.setCurItem(data);
                    this.setAction('update');

                    return data;
                }),
                catchError(this.handleError)
            );
    }

    public delete(id: number | string): Observable<T> {
        return this.http.delete<T>(this.url + id, this.getHeaders())
            .pipe(
                map(data => {
                    this.setCurItem(data);
                    this.setAction('delete');
                    return data;
                }),
                catchError(this.handleError)
            );
    }

    public setCurItem(entity: T): void {
        if (!entity) {
            this._curItem.next(entity);
            return;
        }

        if ('dataNascimento' in entity && typeof entity['dataNascimento'] == 'number') {
            entity['dataNascimento'] = this.customDate(entity['dataNascimento']);
        }
        if ('data' in entity && typeof entity['data'] == 'number') {
            entity['data'] = this.customDate(entity['data']);
        }
        if ('dataCadastro' in entity && typeof entity['dataCadastro'] == 'string') {
            entity['dataCadastro'] = new Date(entity['dataCadastro']);
        }
        if ('dataAlteracao' in entity && typeof entity['dataAlteracao'] == 'string') {
            entity['dataAlteracao'] = new Date(entity['dataAlteracao']);
        }

        this._curItem.next(entity);
    }

    public setAction(act: string): void {
        this._action.next(act);
    }

    public getCurItem(): Observable<T> {
        return this._curItem.asObservable();
    }

    public updateArrayList(arr, obj, field?: string) {
        let index;
        let object;

        if (!obj) {
            return false;
        }

        if (field) {
            object = arr.filter(element => element[field] == obj[field]);
            index = arr.indexOf(object[0]);
        } else {
            object = arr.filter(element => element.id == obj.id);
            index = arr.indexOf(object[0]);
        }

        if (index >= 0) {
            arr[index] = obj;
        } else if (index < 0) {
            arr.splice(0, 0, obj);
        }
    }

    public removeItemFromArrayList(arr, obj, field: string) {
        const object = arr.filter(element => element[field] == obj[field]);
        const index = arr.indexOf(object[0]);

        arr.splice(index, 1);
    }

    public customDate(numero: string | number | Date) {
        if (!numero) {
            return;
        }

        if (typeof numero != 'number') {
            return new Date(numero);
        }

        const numeroString = numero.toString();
        const ano = Number(numeroString.substr(0, 4));
        const mes = Number(numeroString.substr(4, 2));
        const dia = Number(numeroString.substr(6, 2));

        return new Date(ano, mes - 1, dia, 0, 0);
    }

    protected handleError(resError: HttpErrorResponse) {
        let data: any;
        let message = '';
        switch (resError.status) {
            case 500:
                data = { status: resError.status, message: resError.message };
                break;
            case 400:
                if (typeof resError.error == 'string') {
                    message = resError.error;
                } else if (resError.error.erros != null) {
                    for (let i = 0; i < resError.error.erros.length; i++) {
                        message = message.concat('- ', resError.error.erros[i].value, '\n');
                    }
                } else if (resError.error.error_description != null) {
                    message = resError.error.error_description;

                } else if (resError.error != null) {
                    message = resError.error.Message;
                }

                data = { status: false, message: message };
                break;
            case 401:
                data = { status: false, message: 'Sua sessão expirou!' };
                break;
            case 403:
                data = { status: false, message: 'Você não tem permissão para executar esta ação.' };
                break;
            case 404:
                data = { status: false, message: 'Recurso não localizado' };
                break;
            case 422:
                if (resError.error?.type == 'modal') {
                    data = { status: false, type: 'modal',title: resError.error.title, message: resError.error.message };
                } else {
                    data = { status: false, message: 'Você não tem permissão para executar esta ação' };
                }
                break;
            default:
                data = { status: false, message: 'Não foi possível conectar ao servidor, verifique sua conexão com a internet.\n' };
                break;
        }

        return throwError(data || { status: false, message: 'Server error' });
    }

    protected getHeaders() {
        const sessionData = this.getNavigatorSessionData();

        if (sessionData && sessionData.access_token) {
            const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + sessionData.access_token);

            return { headers: httpHeaders };
        }
    }

    protected getNavigatorSessionData() {
        const access_token = this.api.authToken + '-' + this.api.enviroment;

        const sessionData: AppSession = Object.assign({}, JSON.parse(window.localStorage.getItem(access_token)));

        const isRecoveringPassword: string = window.localStorage.getItem("isRecoveringPassword");

        // if (!sessionData.expiration_date && Math.round(new Date().getTime()) > sessionData.expiration_date || isRecoveringPassword == "false") {
        if ((!sessionData.expiration_date && Math.round(new Date().getTime()) > sessionData.expiration_date) && isRecoveringPassword == "false") {
            this.router.navigate(['/', 'auth', 'login']);
            // console.log(sessionData);
            return {} as AppSession;
        }

        return sessionData;
    }

    public getAddressByCEP(cep: string): Observable<Endereco> {
        const url = `https://viacep.com.br/ws/${cep}/json`;

        return this.http.get<Endereco>(url)
            .pipe(
                delay(1000),
                map(endereco => {
                    return <Endereco>{
                        logradouro: endereco.logradouro,
                        complemento: endereco.complemento,
                        bairro: endereco.bairro,
                        cidade: endereco.localidade,
                        uf: endereco.uf
                    };
                }),
                catchError(this.handleError)
            );
    }
}

export interface IEntity {
    id: number | string;
}
