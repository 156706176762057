import { Injectable, Injector } from '@angular/core';
import { CoreService } from '../../../shared/core.service';
import { catchError, map } from 'rxjs/operators';
import { Observable} from 'rxjs';
import { AvisosNoticias } from '../model/AvisosNoticias';

@Injectable()
export class AvisosNoticiasService  extends CoreService<AvisosNoticias> {
    protected url = this.api.url + 'avisos/';

    constructor(injector: Injector) {
        super(injector);
    }

    public getNews(pagina: number): Observable<AvisosNoticias> {
        const url = `${this.url}?pagina=${pagina}`;

        return this.http.get<AvisosNoticias>(url, this.getHeaders())
            .pipe(
                map(data => data),
                catchError(this.handleError)
            );
    }
}
