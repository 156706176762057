import { Injectable } from '@angular/core';
import { Router, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../shared';

import { Permissao } from '../shared/models';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        let authorized: boolean;

        if (this.authService.authState()) {
            if (route.routeConfig.path == '' || route.routeConfig.path == 'print' || route.routeConfig.path == 'configuracoes') {
                return true;
            }

            if (!this.authService.resourceList.length) {
                const segment = (route.routeConfig.path == 'reports') ? ['/', 'print', 'inicio', 'wait'] : ['/', 'inicio', 'wait'];
                this.router.navigate(segment, { queryParams: { urlRedirect: state.url } });
                return false;
            }

            if (route.routeConfig.path == ':id') {
                const routeConfig = this.getRouteConfig(route.children[0]);
                const resourceName = routeConfig.component['resourceName'];

                authorized = this.authService.hasPermission(resourceName, Permissao.visualizar);
            } else {
                const routeConfig = this.getRouteConfig(route.children[0]);
                const resourceName = routeConfig.component['resourceName'];

                authorized = this.authService.hasPermission(resourceName, Permissao.visualizar);
            }

            if (!authorized) {
                const routeConfig = this.getRouteConfig(route.children[0]);
                this.toastr.warning('Você não tem permissão para Visualizar este item.');
            }

            return authorized;
        }

        this.router.navigate(['/auth/login']);

        return false;
    }

    private getRouteConfig(route: ActivatedRouteSnapshot): Route {
        if (!!route.component) {
            return route.routeConfig;
        }

        return this.getRouteConfig(route.children[0]);
    }

}
