import { Injectable, Injector } from '@angular/core';

import { CoreService } from '../../../shared/core.service';

import { Empresa } from '../models';

@Injectable()
export class EmpresaService extends CoreService<Empresa> {
    private windowLocalStorage = window.localStorage;

    protected url = this.api.url + 'empresa/';
    private localStorageItem = this.api.authToken + '-empresa-' + this.api.enviroment as string;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    public setIntoLocalStorage(empresa: Empresa): void {
        if (!empresa) {
            return;
        }

        this.windowLocalStorage.removeItem(this.localStorageItem);

        this.windowLocalStorage.setItem(this.localStorageItem, JSON.stringify(empresa));
    }

    public getFromLocalStorage(): Empresa {
        const strEmpresa = this.windowLocalStorage.getItem(this.localStorageItem);

        if (strEmpresa) {
            return JSON.parse(strEmpresa) as Empresa;
        }

        return {} as Empresa;
    }

    public getAllFromLocalStorage(): Empresa[] {
        const authToken = this.windowLocalStorage.getItem(this.api.authToken + '-' + this.api.enviroment);

        if (authToken) {
            const empresas = JSON.parse(authToken)['empresas'] as Empresa[];

            if (empresas && empresas.length) {
                return empresas;
            }
        }

        return [] as Empresa[];
    }

}
