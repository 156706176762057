import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmpresaRoutingModule } from './empresa-routing.module';

import { EmpresaService } from './shared/services';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EmpresaRoutingModule
  ],
  exports: [],
  providers: [
    EmpresaService
  ]
})
export class EmpresaModule { }
