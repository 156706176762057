import { Component, Input } from '@angular/core';

import { IMensalidade } from '../../models';

@Component({
  selector: 'app-mensalidade-label-status',
  templateUrl: './mensalidade-label-status.component.html',
  styleUrls: ['./mensalidade-label-status.component.scss']
})
export class MensalidadeLabelStatusComponent {
  @Input() public mensalidade: IMensalidade;

  public status = {
    'Atrasada': {
      background: 'bg-danger',
      label: 'Atrasada'
    },
    'AVencer': {
      background: 'bg-light',
      label: 'A vencer'
    },
    'Paga': {
      background: 'bg-success',
      label: 'Paga'
    },
    'Cancelada': {
      background: 'bg-secondary',
      label: 'Cancelada'
    },
    'Parcial': {
      background: 'bg-warning',
      label: 'Parcial'
    }
  };

}
