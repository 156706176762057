import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EditComponent } from '../../../../shared/components';

import { MatriculaService } from '../../services';
import { AgendaService } from '../../../../agenda/shared/services';
import { ModalidadeService } from '../../../../modalidades/shared/modalidade.service';
import { ProfessionalService } from '../../../../professional/shared';

import { Matricula, weekDayCheckboxGroup, WeekDayCheckbox } from '../../models';
import { Plano } from '../../../../plano/shared/models';
import { Modalidade } from '../../../../modalidades/shared/models';
import { Cliente } from '../../../../cliente/shared/models';
import { Professional } from '../../../../professional/shared/models';
import { IAgendaDetalhes } from '../../../../agenda/shared/models';

@Component({
  selector: 'app-matricula-form-edit',
  templateUrl: './matricula-form-edit.component.html',
  styleUrls: ['./matricula-form-edit.component.scss']
})
export class MatriculaFormEditComponent extends EditComponent<Matricula> implements OnInit {
  static resourceName = 'Matricula';
  public clients: Cliente[] = [<Cliente>{}];
  public listaProfissionais: Professional[] = [];
  public listaModalidade$: Observable<Modalidade[]>;
  public listaPlanoModalidade: Plano[] = [];
  public weekDayCheckboxGroup = Object.assign(weekDayCheckboxGroup);
  public workingHours$: Observable<{hora: number, descricao: string}[]>;
  public listaAgendas: IAgendaDetalhes[] = [];

  public saveButton = true;
  public saveButtonLabel = 'Alterar';

  constructor(
    protected injector: Injector,
    protected service: MatriculaService,
    private agendaService: AgendaService,
    private modalidadeService: ModalidadeService,
    private professionalService: ProfessionalService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    this.workingHours$ = this.agendaService.listHour;
    this.listaModalidade$ = this.modalidadeService.getList();
    this.getProfessionalList();
    this.buscarAgendasFuturas();

    super.ngOnInit();

    this.clients.push(this.item.aluno);
    this.listaPlanoModalidade.push(this.item.plano);

    this.formGroup.controls.modalidadeId.setValue(this.item.plano.modalidadeId);

    this.formGroup.controls.alunoId.disable();
    this.formGroup.controls.modalidadeId.disable();
    this.formGroup.controls.planoId.disable();
  }

  public save() {
    this.submitted = true;

    if (!this.listaAgendas || !this.listaAgendas.length) {
      this.toastr.info('Não há agendamentos para modificar', 'Informação');
      return;
    }

    const formGroupValues = this.formGroup.getRawValue();
    formGroupValues.dataInicioPeriodo = (<Date>this.formGroup.controls.dataInicioPeriodo.value).toDateString();
    formGroupValues.dataFimPeriodo = (<Date>this.formGroup.controls.dataFimPeriodo.value).toDateString();

    if (this.formGroup.invalid) {
      this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
      return;
    }

    this.blockui.start();

    this.service.alterarHorarios(formGroupValues)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.toastr.success('Horários alterados com sucesso!', 'Sucesso');
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  public initFormControls() {
    this.formControls = Object.assign({
      id: new UntypedFormControl(0),
      alunoId: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      modalidadeId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      planoId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      todosAgendamentos: new UntypedFormControl(true),
      horarioProfissionalDiferente: new UntypedFormControl(false),
      geralHora: new UntypedFormControl(0),
      geralFuncionarioId: new UntypedFormControl(0),
      dom: new UntypedFormControl(false),
      domHora: new UntypedFormControl(0),
      domFuncionarioId: new UntypedFormControl(0),
      seg: new UntypedFormControl(false),
      segHora: new UntypedFormControl(0),
      segFuncionarioId: new UntypedFormControl(0),
      ter: new UntypedFormControl(false),
      terHora: new UntypedFormControl(0),
      terFuncionarioId: new UntypedFormControl(0),
      qua: new UntypedFormControl(false),
      quaHora: new UntypedFormControl(0),
      quaFuncionarioId: new UntypedFormControl(0),
      qui: new UntypedFormControl(false),
      quiHora: new UntypedFormControl(0),
      quiFuncionarioId: new UntypedFormControl(0),
      sex: new UntypedFormControl(false),
      sexHora: new UntypedFormControl(0),
      sexFuncionarioId: new UntypedFormControl(0),
      sab: new UntypedFormControl(false),
      sabHora: new UntypedFormControl(0),
      sabFuncionarioId: new UntypedFormControl(0),
      dataInicioPeriodo: new UntypedFormControl(null, Validators.required),
      dataFimPeriodo: new UntypedFormControl(null, Validators.required)
    });
  }

  public onCheckDifferentTime(): void {
    const horarioDiferente = this.formGroup.controls.horarioProfissionalDiferente.value;

    if (horarioDiferente) {
      this.formGroup.controls.geralHora.disable();
      this.formGroup.controls.geralFuncionarioId.disable();

      this.formGroup.controls.geralHora.clearValidators();
      this.formGroup.controls.geralFuncionarioId.clearValidators();

      this.setValidatorsWeekDayCheckbox();
    }  else if (!horarioDiferente) {
      this.formGroup.controls.geralHora.enable();
      this.formGroup.controls.geralFuncionarioId.enable();

      this.formGroup.controls.geralHora.setValidators([Validators.required, Validators.min(1), Validators.max(23)]);
      this.formGroup.controls.geralFuncionarioId.setValidators([Validators.required, Validators.min(1)]);

      this.clearValidatorsWeekDayCheckbox();
    } else {
      this.formGroup.controls.geralHora.enable();
      this.formGroup.controls.geralFuncionarioId.enable();

      this.clearValidatorsWeekDayCheckbox();
    }
  }

  public onCheckWeekDay(wdCheckBox: WeekDayCheckbox): void {
    if (this.formGroup.controls[wdCheckBox.id].value && this.formGroup.controls.horarioProfissionalDiferente.value) {
      this.formGroup.controls[wdCheckBox.controlHour.id].enable();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].enable();

      this.formGroup.controls[wdCheckBox.controlHour.id]
      .setValidators([Validators.required, Validators.min(1), Validators.max(23)]);

      this.formGroup.controls[wdCheckBox.controlProfessional.id]
      .setValidators([Validators.required, Validators.min(1)]);
    } else if (this.formGroup.controls[wdCheckBox.id].value && !this.formGroup.controls.horarioProfissionalDiferente.value) {
      this.formGroup.controls[wdCheckBox.controlHour.id].disable();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].disable();

      this.formGroup.controls[wdCheckBox.controlHour.id].clearValidators();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].clearValidators();
    } else {
      this.formGroup.controls[wdCheckBox.controlHour.id].disable();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].disable();
    }
  }

  private clearValidatorsWeekDayCheckbox(): void {
    this.weekDayCheckboxGroup.forEach((el) => {
        this.formGroup.controls[el.controlHour.id].disable();
        this.formGroup.controls[el.controlProfessional.id].disable();
    });
  }

  private setValidatorsWeekDayCheckbox(): void {
    this.weekDayCheckboxGroup.forEach((el) => {
      if (this.formGroup.controls[el.id].value) {
        this.formGroup.controls[el.controlHour.id].enable();
        this.formGroup.controls[el.controlHour.id]
        .setValidators([Validators.required, Validators.min(1), Validators.max(23)]);

        this.formGroup.controls[el.controlProfessional.id].enable();
        this.formGroup.controls[el.controlProfessional.id]
        .setValidators([Validators.required, Validators.min(1)]);
      }
    });
  }

  private getProfessionalList(): void {
    this.professionalService.getList('agenda').subscribe(
      professionalList => {
        this.listaProfissionais = professionalList;
      },
      error => {
        console.log(error.message, 'Error');
      }
    );
  }

  private buscarAgendasFuturas() {
    this.blockui.start();

    this.agendaService.getList('futuras/' + this.item.id.toString())
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      data => {
        this.listaAgendas = data;

        if (!data || !data.length) {
          return;
        }

        const dataInicioPeriodo = data[0].data,
              dataFimPeriodo = data[data.length - 1].data;

        if (dataInicioPeriodo) {
          this.formGroup.controls.dataInicioPeriodo
          .setValue(this.service.customDate(dataInicioPeriodo));
        }

        if (dataFimPeriodo) {
          this.formGroup.controls.dataFimPeriodo
          .setValue(this.service.customDate(dataFimPeriodo));
        }
      },
      error => {
        this.toastr.error(error.message);
        this.modal.hide();
      }
    );
  }

}
