import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CoreService } from '../../../shared/core.service';

import { Matricula } from '../models';
import { FormaPagamento } from '../../../configuracoes/pagamento/shared/models';
import { IMensalidade, MensalidadeMatriculaArgs } from '../../../financeiro/shared/models';
import { Cliente } from '../../../cliente/shared/models';
import { ArgsUpdateInvoiceValues } from '../../../financeiro/shared/models/iMensalidade';

@Injectable()
export class MatriculaService extends CoreService<Matricula> {
  protected url: string = this.api.url + 'matriculas/';

  constructor(protected injector: Injector) {
    super(injector);
  }

  public searchClient(searchTerm: string): Observable<Cliente[]> {
    const url = `${this.url}alunos/${searchTerm}`;

    return this.http.get<Cliente[]>(url, this.getHeaders()).pipe(
      map(list => list),
      catchError(this.handleError)
    );
  }

  public searchFreeSchedule(nomeAluno: string, modalidadeId: number): Observable<any> {
    const url = this.url + 'livre' + '/' + nomeAluno + '/' + modalidadeId.toString();

    return this.http.get(url, this.getHeaders()).pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public renew(entity: Matricula): Observable<Matricula> {
    return this.http.post<Matricula>(this.url, entity, this.getHeaders())
    .pipe(
      map(matricula => {
        this.setCurItem(matricula);
        this.setAction('update');
        return matricula;
      }),
      catchError(this.handleError)
    );
  }

  public alterarHorarios(entity: Matricula) {
    return this.http.put<Matricula>(`${this.url}alterarhorarios`, entity, this.getHeaders())
    .pipe(
      map(matricula => {
        this.setCurItem(matricula);
        this.setAction('update');
        return matricula;
      }),
      catchError(this.handleError)
    );
  }

  public paymentMethods(): Observable<FormaPagamento[]> {
    const url = `${this.url}formasPagamento`;

    return this.http.get<FormaPagamento[]>(url, this.getHeaders()).pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public invoicesCalculate(args: MensalidadeMatriculaArgs): Observable<IMensalidade[]> {
    const url = `${this.url}mensalidades`;

    return this.http.post<IMensalidade[]>(url, args, this.getHeaders()).pipe(
      map(data => {
        data.map(el => el.dataVencimento = new Date(el.dataVencimento.toString().slice(0, -1)));
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public updateInvoiceDates(args: IMensalidade[]): Observable<IMensalidade[]> {
    const url = `${this.url}mensalidades/datas`;

    return this.http.put<IMensalidade[]>(url, args, this.getHeaders()).pipe(
      map(data => {
        data.map(el => el.dataVencimento = new Date(el.dataVencimento.toString().slice(0, -1)));
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public updateInvoiceValues(args: ArgsUpdateInvoiceValues): Observable<IMensalidade[]> {
    const url = `${this.url}mensalidades/valores`;

    return this.http.put<IMensalidade[]>(url, args, this.getHeaders()).pipe(
      map(data => {
        data.map(el => el.dataVencimento = new Date(el.dataVencimento.toString().slice(0, -1)));
        return data;
      }),
      catchError(this.handleError)
    );
  }
}
