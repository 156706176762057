import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Professional } from '../../models';

@Component({
  selector: 'app-selectbox-professional-id',
  templateUrl: './selectbox-professional-id.component.html',
  styleUrls: ['./selectbox-professional-id.component.scss']
})
export class SelectboxProfessionalIdComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() professionalList: Professional[] = [];
  @Input() initialValue: number;
  @Input() initialValueDescription: string;
  @Input() autoHideInitialValue: boolean;
  @Input() name: string;
  @Input() label: string;
  @Input() classCss: string;
  @Input() readonly: boolean;
  @Input() formSubmitted: boolean;
  @Input() invalid: boolean;
  @Input() validationMessage: string;
  @Output() changeValue = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.initialValue = this.initialValue ? this.initialValue : 0;
    this.initialValueDescription = this.initialValueDescription ? this.initialValueDescription : 'Todos';
    this.classCss = this.classCss ? `form-control ${this.classCss}` : 'form-control';
    this.validationMessage = this.validationMessage ? this.validationMessage : 'Selecione um Profissional!';
  }

  public onChangeProfessionalId() {
    const professional = this.professionalList.filter(el => el.id == this.form.get(this.name).value)[0];

    this.changeValue.emit(professional);
  }

}
