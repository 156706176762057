import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CoreService } from '../../shared/core.service';
import { Reposicao } from './models';
import { AgendaDetalhesSimples, AgendaDetalhes } from '../../agenda/shared/models';

@Injectable()
export class ReposicaoService extends CoreService<Reposicao> {
  protected url: string = this.api.url + 'reposicoes/';

  constructor(protected injector: Injector) {
    super(injector);
  }

  public insert(agenda: AgendaDetalhesSimples): Observable<any> {
    return this.http.post<AgendaDetalhes>(this.url, agenda, this.getHeaders())
    .pipe(
       map(data => data),
       catchError(this.handleError)
    );
  }

  public searchByClient(name: string, modalidadeId: number): Observable<any> {
    const url = this.url + name + '/' + modalidadeId.toString();

    return this.http.get(url, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public searchByModality(modalidadeId: number): Observable<any> {
    const url = this.url + modalidadeId.toString();

    return this.http.get(url, this.getHeaders())
      .pipe(
        map(data => data),
        catchError(this.handleError)
      );
  }

  public bulkCancel(ids: number[]): Observable<Reposicao[]> {
    return this.http.put<Reposicao[]>(this.url + 'cancelar', ids, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

}
