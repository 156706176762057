import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-window-confirm',
  templateUrl: './modal-window-confirm.component.html',
  styleUrls: ['./modal-window-confirm.component.scss']
})
export class ModalWindowConfirmComponent {
  private _result = new BehaviorSubject (null);
  public result: Observable<any> = this._result.asObservable ();

  public message: string;
  public dialogTitle: string;
  public contentText = 'Deseja continuar?';
  public contentQuestion = '';

  constructor (
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router
  ) {
    const subNavigate = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        modalRef.hide();
        subNavigate.unsubscribe();
      }
    });
  }

  confirm(): void {
    this.modalService.setDismissReason('true');
    this.modalRef.hide();
    this._result.next(true);
  }

  decline(): void {
    this.modalService.setDismissReason('false');
    this.modalRef.hide();
    this._result.next(false);
  }
}
