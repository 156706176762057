import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { ActivatedRoute} from '@angular/router';

import { NgBlockUI, BlockUI} from 'ng-block-ui';
import { ToastrService} from 'ngx-toastr';

import { AuthService} from '../shared';
// import { Registro } from '../shared/models';
import {Title} from '@angular/platform-browser';
// import {Login} from '../shared/models';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-recover-code',
  templateUrl: './recover-code.component.html',
  styleUrls: ['./recover-code.component.scss']
})

export class RecoverCodeComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;

  public isSubmitted: boolean;

  private hash: string;
  public recoverCode: string;

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Código de Recuperação');
    // console.log(JSON.parse(window.localStorage.getItem("access_token")));

    window.localStorage.setItem("isRecoveringPassword", "true");

    this.route.queryParams
      .subscribe(params => {
        this.hash = params.hash;
      });
  }

  public validateRecoverCode() {

    const credentials = {
      codigo: this.recoverCode,
      hash: this.hash,
    };

    this.isSubmitted = true;

    if (!this.recoverCode) {
      this.toastrService.error('É necessário um código!');
      return;
    }

    if (this.recoverCode.length < 6) {
      this.toastrService.error('Código inválido!');
      return;
    }

    this.blockui.start();

    this.authService.validateCode(credentials.codigo, credentials.hash)
      .pipe(finalize(this.blockui.stop))
      .subscribe({
        next: () => {
          this.blockui.start();
          window.localStorage.removeItem("isRecoveringPassword")
          this.router.navigate(
            ['/', 'auth', 'password-reset'],
            { queryParams: { recoverCode: this.recoverCode, hash: this.hash }}
          )
        }, error: () => {
          this.toastrService.error("Código inválido");
          return
        }
      });
  }
}

