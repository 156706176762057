import { Injectable, Injector } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CoreService } from '../../../shared/core.service';

import { User, LoggedUser } from '../models';

@Injectable()
export class UserService extends CoreService<User> {
    protected url: string = this.api.url + 'usuarios/';

    private _loggedUser: BehaviorSubject<LoggedUser> = new BehaviorSubject(null);
    public loggedUser: Observable<LoggedUser> = this._loggedUser.asObservable();

    public usuarioLogado: LoggedUser;

    constructor(protected injector: Injector) {
        super(injector);

        this._loggedUser.next(null);
    }

    public getLoggedUser(): Observable<LoggedUser> {
        const url = this.api.url + 'usuarios/logado';

        return this.http.get<LoggedUser>(url, this.getHeaders())
            .pipe(
                map((data) => {
                    this._loggedUser.next(data);
                    this.usuarioLogado = data;

                    return data;
                }),
                catchError(this.handleError)
            );

    }
    
    public refresh(showMenu: boolean) {
        this.usuarioLogado.mostrarPainelInstrutor = showMenu;
    }

    public refreshComissao(showComissao: boolean) {
        this.usuarioLogado.mostrarPainelInstrutorComissao = showComissao;
    }

    public refreshAulas(showAulas: boolean) {
        this.usuarioLogado.mostrarPainelInstrutorAulas = showAulas;
    }
}
