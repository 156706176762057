import { AbstractControl } from '@angular/forms';

export class ValidatePassword {

    static MatchPassword(control: AbstractControl) {
        const senha = control.get('password').value;
        const confirmaSenha = control.get('passwordConfirm').value;

        if (!senha || !confirmaSenha || senha !== confirmaSenha) {
            control.get('passwordConfirm').setErrors({MatchPassword: true});
        } else {

            return null;
        }
    }

}
