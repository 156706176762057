import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-datepicker-bs',
  templateUrl: './datepicker-bs.component.html',
  styleUrls: ['./datepicker-bs.component.scss']
})
export class DatepickerBsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() name: string;
  @Input() label: string;
  @Input() classCss: string;
  @Input() placement: string;
  @Input() readonly: boolean;
  @Input() formSubmitted: boolean;
  @Input() maxDate: Date;
  @Input() minDate: Date;

  public formControlClass = 'form-control';
  public btnInputGroupClass = 'btn btn-secondary input-group-btn is-invalid';

  constructor() { }

  ngOnInit() {
    if (this.classCss) {
      this.formControlClass = this.classCss;
      this.btnInputGroupClass = 'btn btn-secondary input-group-btn btn-sm';
    }

    this.placement = this.placement ? this.placement : 'bottom left';

    if (!this.minDate) {
      this.minDate = new Date(1900, 0, 1, 0);
    }

    if (!this.maxDate) {
      this.maxDate = new Date(9999, 12, 31, 0);
    }
  }

  public dateMask(event: any) {
    const v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }

}
