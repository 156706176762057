import { Component, Injector } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { EditComponent } from '../../../../shared/components';

import { Matricula } from '../../models';

import { MatriculaService } from '../../services';

@Component({
  selector: 'app-tranca-matricula',
  templateUrl: './tranca-matricula.component.html',
  styleUrls: ['./tranca-matricula.component.scss']
})
export class TrancaMatriculaComponent extends EditComponent<Matricula> {
  static resourceName = 'Matricula';

  public startAction = false;

  public dialogTitle = 'Trancar Matrícula';
  public formIcon = 'fa fa-pause';
  public saveButton = true;
  public saveButtonLabel = 'Trancar';

  constructor(
    protected injector: Injector,
    service: MatriculaService
  ) {
    super(injector, service);
  }

  public save() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
      return;
    }

    this.blockui.start();

    const item = this.formGroup.getRawValue() as Matricula;
    const successMessage = this.startAction ? 'Matrícula destrancada.' : 'Matrícula trancada.';

    item.dataInicio = new Date(item.dataInicio).toDateString();

    const endpoit = this.startAction ? 'destrancar' : 'trancar';
    this.service.put(item, endpoit)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
        () => {
            this.toastr.success(successMessage);
            this.modal.hide();
        },
        error => {
            this.toastr.error(error.message);
        }
    );

  }

  public initFormControls () {
    this.formControls = {
      matriculaId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      inicio: new UntypedFormControl(new Date(), Validators.required),
      motivo: new UntypedFormControl('', [Validators.required, Validators.minLength(3)])
    };

    if (this.startAction) {
      this.dialogTitle = 'Destrancar matrícula';
      this.formIcon = 'fa fa-play';
      this.saveButtonLabel = 'Destrancar';
      this.formControls.motivo.clearValidators();
    }
  }

  protected patchFormGroup() {
    this.item.matriculaId = this.item.id;

    super.patchFormGroup();
  }

}
