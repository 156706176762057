import { Injectable, Injector } from '@angular/core';

import { CoreService } from '../../../shared/core.service';
import { BalancoFinanceiroMes } from '../models';

@Injectable()
export class FinanceiroService extends CoreService<BalancoFinanceiroMes> {
    protected url = this.api.url + 'financeiro/';

    constructor(protected injector: Injector) { super(injector); }
}
