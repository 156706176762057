import { Injectable, Injector } from '@angular/core';

import { CoreService } from '../../shared/core.service';
import { Professional } from './models';

@Injectable()
export class ProfessionalService extends CoreService<Professional> {
    protected url: string = this.api.url + 'funcionarios/';

    constructor(protected injector: Injector) {
      super(injector);
    }
}
