import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  SimpleChanges,
  OnChanges} from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IMensalidade } from '../../models';
import { Cliente } from '../../../../cliente/shared/models';
import { Matricula } from '../../../../matriculas/shared/models';

import { MensalidadeAlunoService, MensalidadeService } from '../../services';
import { MatriculaService } from '../../../../matriculas/shared';

import { ListComponent, ModalWindowConfirmComponent } from '../../../../shared/components';
import { MensalidadeFormAlunoComponent } from '../mensalidade-form';
import { MensalidadeDetalhesAlunoComponent } from '../mensalidade-detalhes';

@Component({
  selector: 'app-lista-mensalidades-cliente',
  templateUrl: './lista-mensalidades-cliente.component.html',
  styleUrls: ['./lista-mensalidades-cliente.component.scss']
})
export class ListaMensalidadesClienteComponent
 extends ListComponent<IMensalidade> implements OnInit, OnDestroy, OnChanges {
  public static resourceName = 'MensalidadeAluno';

  @Input() matricula: Matricula;
  @Input() cliente: Cliente;
  @Output() enviaListaMensalidade = new EventEmitter();

  public formComponent = MensalidadeFormAlunoComponent;
  public formComponentClass = 'modal-md shadow';

  private actionMensalidadeSub: Subscription = new Subscription();
  private actionMatriculaSub: Subscription = new Subscription();

  constructor(
    injector: Injector,
    protected service: MensalidadeAlunoService,
    private matriculaService: MatriculaService,
    private mensalidadeService: MensalidadeService
  ) {
    super(injector, service);

    this.baseListConfig.title = 'Lista de Mensalidades';
    this.baseListConfig.icon = 'fa fa-money';

    this.cliente = {id: 0} as Cliente;
  }

  ngOnInit() {
    this.actionMensalidadeSub = this.service.action.subscribe(action => {
      if (action && action == 'update') {
        this.loadList();
        this.enviaListaMensalidade.emit(this.listOfItem);
        this.mensalidadeService.setCurItem(this.curItem);
        this.mensalidadeService.setAction('update');
      }
    });

    this.actionMatriculaSub = this.matriculaService.action.subscribe(action => {
      if (action && action.match(/delete|update/i)) {
        this.loadList();
      }
    });

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.actionMensalidadeSub.unsubscribe();
    this.actionMatriculaSub.unsubscribe();
    this.mensalidadeService.setCurItem(undefined);
    this.mensalidadeService.setAction(null);
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const inputName in changes) {
      if (inputName == 'cliente') {
        this.baseListConfig.title = 'Mensalidades em Aberto';
      }
    }
  }

  loadList() {
    const endpoint = this.matricula ? 'matricula/' + String(this.matricula.id) : 'aluno/' + String(this.cliente.id);

    this.emptyList = false;
    this.blockui.start();

    this.service.getList(endpoint)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        list => {
          this.listOfItem = list;
          this.listPartialOfItems = this.listOfItem.slice(0, this.baseListConfig.pagination.itemsPerPage);
          if (list.length == 0) {
              this.emptyList = true;
          }

          this.currentPage = 1;
          this.enviaListaMensalidade.emit(this.listOfItem);

          this.showPrintButton();
        },
        error => {
          console.log(error);
          this.toastr.error(error.message, 'Erro');
        }
      );

  }

  private showPrintButton(): void {
    if (this.listOfItem.filter(el => el.status == 'Paga').length == this.listOfItem.length && this.matricula) {
      this.baseListConfig.printButtonIsShown = true;
    } else {
      this.baseListConfig.printButtonIsShown = false;
    }
  }

  public openItemDialog(mensalidade: IMensalidade) {
    if (mensalidade.cancelado) {
      return;
    }

    if (mensalidade.status == 'Paga') {
      this.openInvoiceDetails(mensalidade);
      return;
    }

    mensalidade.permiteBaixarTodas = true;

    super.openItemDialog(mensalidade);
  }

  public openInvoiceDetails(mensalidade: IMensalidade): void {
    this.modalService.show(MensalidadeDetalhesAlunoComponent, {
      class: 'modal-md modal-h80 shadow',
      initialState: {
        item: mensalidade
      }
    });
  }

  public printPage(): void {
    if (!this.permissions.visualizar) {
      this.toastr.warning('Você não tem permissão para Imprimir o Recibo das mensalidades.', 'Atenção');
      return;
    }

    const endpoint = `print/reports/recibo/mensalidade-matricula/${this.matricula.id}`;

    const baseHref = document.getElementsByTagName('base')[0].getAttribute('href');

    const customWindow = window.open(baseHref + endpoint ,
      '_blank', 'top=0,left=0,location=no,status=0,width=' + window.innerWidth + ',height=' + window.innerHeight);
  }

}
