import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AssinaturaService } from '../../home/shared';
import { AssinaturaCriar } from '../../home/shared/models/assinatura';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public formSubmitted: boolean;
  public form: UntypedFormGroup;
  @ViewChildren('name') public name: QueryList<ElementRef>;
  @BlockUI() public blockui: NgBlockUI;

  constructor(private titleService: Title,
    private toastr: ToastrService,
    private assinaturaService: AssinaturaService) {
    this.form = new UntypedFormBuilder().group({
      name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      userEmail: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.minLength(11)]]
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Agenda Pilates - Registre-se grátis');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.name.first.nativeElement.focus();
    }, 500);
  }

  submit() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    this.blockui.start();

    const values = this.form.getRawValue();
    const obj: AssinaturaCriar = {
      email: values.userEmail,
      nome: values.name,
      telefone: values.phone,
      plano: "mensal" // valor padrão
    };

    this.assinaturaService.criarAssinatura(obj)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        res => {
          // this.form.reset();
          this.toastr.success('Foi enviado um e-mail com as instruções para acessar o Portal.', 'Sucesso');
        },
        error => {
          this.toastr.error('Não foi possível concluir o seu cadastro. Tente novamente em alguns instantes.', 'Erro');
        }
      )
  }
}
