import { Component, Injector, OnInit } from '@angular/core';
import { ListDirective } from '../shared/components';
import { AvisosNoticiasService } from './shared/service/avisosNoticias.service';
import { AvisosNoticias } from './shared/model/AvisosNoticias';



@Component({
    selector: 'avisos-noticias',
    templateUrl: './avisos-noticias.component.html'
})
export class AvisosNoticiasComponent extends ListDirective<AvisosNoticias> implements OnInit {
    public listaNoticias: AvisosNoticias[] = [];

    public config: any = {
        totalItems: 10,
        currentPage: 1,
        pagination: {
            itemsPerPage: 10,
            rotate: true,
            previousText: 'Anterior',
            nextText: 'Próximo'
        }
    };

    constructor(
        protected service: AvisosNoticiasService,
        protected injector: Injector,
    ) {
        super(injector, service);
    }

    ngOnInit() {
        this.getList();
    }

    protected getList() {
        this.blockui.start();

        this.service.getNews(this.config.currentPage)
            .subscribe(
                (data: any) => {
                    this.config.totalItems = data.totalItems;
                    this.listaNoticias = data.data;

                    window.scrollTo(0, 0);
                    this.blockui.stop();
                },
            );
    }

    pageChanged(event: any): void {
        this.config.currentPage = event.page;
        this.getList();
    }

    convertDate(date: Date): string {
        const dateObj = new Date(date);

        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();

        return `${day}/${month}/${year}`;
    }

}
