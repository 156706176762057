import { Injectable, Injector } from '@angular/core';

import { CoreService } from '../../shared/core.service';
import { VencimentoMensalidade } from './models';

@Injectable()
export class VencimentoMensalidadeService extends CoreService<VencimentoMensalidade> {
    protected url = `${this.api.url}parametros/diaVencimento/`;

    constructor(protected injector: Injector) {
        super(injector);
    }
}
