import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Professional } from '../../../professional/shared/models';
import { ProfessionalService } from '../../../professional/shared';
import { EditComponent, ModalWindowConfirmComponent } from '..';
import { AgendaDetalhes, IAgendaDetalhes } from '../../../agenda/shared/models';
import { AgendaService } from '../../../agenda/shared/services';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AgendaJustificativa } from '../../../agenda/shared/models/AgendaJustificativa';

@Component({
  selector: 'modal-confirm-optional-text.component',
  templateUrl: './modal-confirm-optional-text.component.html'
})

export class ModalConfirmOptionalTextComponent extends EditComponent<AgendaJustificativa> implements OnInit, OnDestroy {
  @Input() agendaId: number;
  @Input() funcionarioId: number;

  private _result = new BehaviorSubject(null);
  public result: Observable<any> = this._result.asObservable();
  public message: string;
  public dialogTitle: string;
  public contentText: any;
  public contentQuestion: string = '';
  public justificativa: string = '';
  public instructorList: Professional[] = [];
  public listHour$: Observable<{ hora: number, descricao: string }[]>;
  public show: boolean = false;
  public justificar: boolean = false;
  public justificativasAtuais: number;
  public justificativasRestantes: number;
  public reagendar: boolean;
  public hora: number;
  public data: Date;

  constructor(
    protected injector: Injector,
    protected service: AgendaService,
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public router: Router,
    private profissionalService: ProfessionalService,
  ) {
    super(injector, service);
    const subNavigate: Subscription = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        modalRef.hide();
        subNavigate.unsubscribe();
      }
    });
    this.findInstructors();

    this.listHour$ = this.service.listHour;
  }

  public initFormControls(): void {
    this.formControls = {
      reagendar: new UntypedFormControl(false),
      data: new UntypedFormControl(),
      hora: new UntypedFormControl(),
      funcionarioId: new UntypedFormControl(''),
      justificativa: new UntypedFormControl('')
    };
  }

  ngOnInit() {
    this.initFormControls();
    this.getAgendaDetail();

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.service.setCurItem(null);
    this.service.setAction(null);
    this.justificativa;
    super.ngOnDestroy();
  }

  private getAgendaDetail() {
    this.blockui.start();

    this.service.get("justificativas/" + this.agendaId).subscribe(
      (agenda: AgendaDetalhes) => {
        this.justificar = agenda.justificar;
        this.justificativasAtuais = agenda.justificativasAtuais;
        this.justificativasRestantes = agenda.justificativasRestantes;
        this.item = agenda;
        this.show = true;
        this.formGroup.get('funcionarioId').setValue(this.funcionarioId);

        this.blockui.stop();
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      },
    );
  }


  private findInstructors() {
    this.profissionalService.getList('agenda').subscribe(
      list => {
        this.instructorList = list;
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  public checkFutureDate(): boolean {
    if (typeof this.item.data !== 'object') {
      return;
    }

    const curDate = new Date();
    const curDatetime = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), 0, 0).getTime();
    const scheduleDate = this.item.data;
    const scheduleDateTime = new Date(scheduleDate.getFullYear(), scheduleDate.getMonth(), scheduleDate.getDate(), 0, 0).getTime();

    return scheduleDateTime > curDatetime;
  }

  private justificarAgenda() {
    const agendaDetalhes = new AgendaJustificativa(this.agendaId, this.formControls['justificativa'].value, this.funcionarioId, this.formControls['hora'].value, this.formControls['data'].value, this.formControls['reagendar'].value);

    this.service.updateAgendaJustificativa(agendaDetalhes).pipe().subscribe(
      () => {
        this.modalService.setDismissReason('true');
        this.modal.hide();
        this._result.next(true);
        this.toastr.success("Agenda justificada", 'Sucesso!');
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }


  public confirm(): void {
    if (this.formGroup.invalid) {
      this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
      return;
    }

    if (this.justificativasRestantes == 0) {
      this.modalService.show(ModalWindowConfirmComponent, {
        class: 'modal-centered modal-sencodary',
        backdrop: 'static',
        initialState: {
          dialogTitle: 'Atenção',
          contentText: 'Tem certeza que deseja justificar esta agenda?'
        },
      });

      const modalSub = this.modalService.onHidden.subscribe(action => {
        if (action && action == 'true') {
          this.justificarAgenda();
        }
        modalSub.unsubscribe();
      });
    } else {
      this.justificarAgenda();
    }
  }

  public decline(): void {
    this.modalService.setDismissReason('false');
    this.modalRef.hide();
    this._result.next(false);
  }
}
