import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EditComponent } from '../../../../shared/components';

import { Matricula, WeekDayCheckbox, weekDayCheckboxGroup } from '../../models';
import { Plano, Vigencia } from '../../../../plano/shared/models';
import { Cliente } from '../../../../cliente/shared/models';
import { Professional } from '../../../../professional/shared/models';
import { Modalidade } from '../../../../modalidades/shared/models';
import { ParamService, Param, VencimentoMensalidadeService } from '../../../../param/shared';
import { VencimentoMensalidade } from '../../../../param/shared/models';

import { MatriculaService } from '../../services';
import { AgendaService } from '../../../../agenda/shared/services';
import { ModalidadeService } from '../../../../modalidades/shared/modalidade.service';
import { PlanoService } from '../../../../plano/shared/plano.service';
import { ClienteService } from '../../../../cliente/shared';
import { ProfessionalService } from '../../../../professional/shared';

@Component({
  selector: 'app-matricula-form',
  templateUrl: './matricula-form.component.html',
  styleUrls: ['./matricula-form.component.scss']
})
export class MatriculaFormComponent
  extends EditComponent<Matricula> implements OnInit, OnDestroy {
  static resourceName = 'Matricula';
  public params: Param = <Param>{};

  public clients: Cliente[] = [<Cliente>{}];
  private searchTime;
  public searchClientLoading: boolean;

  public saveButton = true;
  public saveButtonLabel = 'Salvar';
  public dialogTitle = 'Nova Matrícula';

  public workingHours: {hora: number, descricao: string}[] = [];
  public listaProfissionais: Professional[] = [];
  public listaModalidade$: Observable<Modalidade[]>;
  public listaPlanoModalidade$: Observable<Plano[]>;
  public curCliente: Cliente = <Cliente>{};
  public weekDayCheckboxGroup = weekDayCheckboxGroup;
  public listaVencimentoMensalidade$: Observable<VencimentoMensalidade[]>;

  public labelDataInicio: string;

  private subChangeModalidade: Subscription = new Subscription();

  public valorPlano = 0;
  public maxParcelas = 1;

  constructor(
    protected injector: Injector,
    protected service: MatriculaService,
    private agendaService: AgendaService,
    private modalidadeService: ModalidadeService,
    private planoService: PlanoService,
    private clienteService: ClienteService,
    private professionalService: ProfessionalService,
    private paramService: ParamService,
    private vencimentoMensalidadeService: VencimentoMensalidadeService
  ) {
    super(injector, service);

    this.item = <Matricula>{};
  }

  ngOnInit() {
    this.getWorkingHours();
    this.getParams();
    this.getProfessionalList();
    this.listaModalidade$ = this.modalidadeService.getList();

    this.initFormControls();
    this.formGroup = new UntypedFormGroup(this.formControls);

    if (this.item && this.item.id > 0) {
      this.dialogTitle = 'Renovar Matrícula';
      this.saveButtonLabel = 'Renovar';

      this.patchFormGroup();
      this.getListPlanosModalidade();
      this.setValorPlano();
      this.formGroup.controls.modalidadeId.disable();

      if ((typeof this.item.dataFim).match(/string|object/g)) {
        const dataFim = this.item.dataFim as Date,
          year = dataFim.getFullYear(),
          month = dataFim.getMonth(),
          day = dataFim.getDate() + 1;

        this.formGroup.controls.dataInicio.setValue(new Date(year, month, day, 0));
      } else if (typeof this.item.dataFim == 'number') {
        const tmpDate = String(this.item.dataFim),
          year = Number(tmpDate.substr(0, 4)),
          month = Number(tmpDate.substr(4, 2)) - 1,
          day = Number(tmpDate.substr(6, 2)) + 1;
        this.formGroup.controls.dataInicio.setValue(new Date(year, month, day, 0));
      }

      this.onCheckDifferentTimeOrFreeTime();
    }

    if (!this.formGroup.controls.geralFuncionarioId.value) {
      this.formGroup.controls.geralFuncionarioId.setValue(0);
      this.formGroup.controls.geralHora.setValue(0);
    }

    if (this.curCliente && this.curCliente.id > 0) {
      this.formGroup.controls.alunoId.setValue(this.curCliente.id, {});
      this.formGroup.controls.alunoId.disable();

      this.clients.push(this.curCliente);
    }

    this.subChangeModalidade = this.formGroup.controls.modalidadeId.valueChanges.subscribe(() => {
      this.getListPlanosModalidade();
    });
  }

  ngOnDestroy() {
    this.subChangeModalidade.unsubscribe();
    this.formGroup.reset();
    this.service.setCurItem(null);

    super.ngOnDestroy();
  }

  public save() {
    this.submitted = true;
    this.blockui.start();

    const item = this.formGroup.getRawValue() as Matricula;
    item.dataInicio = new Date(item.dataInicio).toDateString();

    if (this.formGroup.controls.id.value == 0) {
      if (this.formGroup.valid) {
        const message = 'Matrícula criada com sucesso.';

        this.service.save(item)
          .pipe(finalize(this.blockui.stop))
          .subscribe(
            () => {
              this.toastr.success(message);
              this.modal.hide();
            },
            error => {
              this.toastr.error(error.message);
            }
          );
      } else {
        this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
        this.blockui.stop();
      }

      return;
    }

    this.formGroup.addControl('matriculaOrigemId', new UntypedFormControl());
    this.formGroup.controls.matriculaOrigemId.setValue(this.formGroup.controls.id.value);

    this.weekDayCheckboxGroup.forEach(v => {
      if (!this.formGroup.controls[v.id].value ||
        (this.formGroup.controls[v.id].value && !this.formGroup.controls.horarioProfissionalDiferente.value)) {
        this.formGroup.controls[v.id + 'Hora'].setValue(0);
        this.formGroup.controls[v.id + 'FuncionarioId'].setValue(0);
      }
    });

    if (this.formGroup.invalid) {
      this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
      return;
    }

    const itemRenew = this.formGroup.getRawValue() as Matricula;
    itemRenew.dataInicio = new Date(itemRenew.dataInicio).toDateString();

    this.service.renew(itemRenew)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        () => {
          this.toastr.success('Matricula renovada!', 'Successo');
          this.modal.hide();
        },
        error => {
          this.toastr.error(error.message, 'Erro');
        }
      );
  }

  public initFormControls() {
    this.formControls = Object.assign({
      id: new UntypedFormControl(0),
      alunoId: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      modalidadeId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      planoId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      desconto: new UntypedFormControl(0),
      parcelas: new UntypedFormControl(0),
      horarioLivre: new UntypedFormControl(false),
      horarioProfissionalDiferente: new UntypedFormControl(false),
      geralHora: new UntypedFormControl(5, [Validators.required, Validators.min(1), Validators.max(23)]),
      geralFuncionarioId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      dom: new UntypedFormControl(false),
      domHora: new UntypedFormControl(0),
      domFuncionarioId: new UntypedFormControl(0),
      seg: new UntypedFormControl(false),
      segHora: new UntypedFormControl(0),
      segFuncionarioId: new UntypedFormControl(0),
      ter: new UntypedFormControl(false),
      terHora: new UntypedFormControl(0),
      terFuncionarioId: new UntypedFormControl(0),
      qua: new UntypedFormControl(false),
      quaHora: new UntypedFormControl(0),
      quaFuncionarioId: new UntypedFormControl(0),
      qui: new UntypedFormControl(false),
      quiHora: new UntypedFormControl(0),
      quiFuncionarioId: new UntypedFormControl(0),
      sex: new UntypedFormControl(false),
      sexHora: new UntypedFormControl(0),
      sexFuncionarioId: new UntypedFormControl(0),
      sab: new UntypedFormControl(false),
      sabHora: new UntypedFormControl(0),
      sabFuncionarioId: new UntypedFormControl(0),
      dataInicio: new UntypedFormControl((new Date()), Validators.required),
      diaVencimento: new UntypedFormControl(0, [Validators.required, Validators.min(1), Validators.max(31)])
    });

    for (const diaSemana of this.weekDayCheckboxGroup) {
      this.formControls[diaSemana.controlHour.id].disable();
      this.formControls[diaSemana.controlProfessional.id].disable();
    }
  }

  public getWorkingHours() {
    this.agendaService.listHour.subscribe(list => {
      this.workingHours = list;
    });
  }

  private getParams() {
    this.paramService.get().subscribe(
      params => {
        this.params = params;

        if (!params.diaFixoVencimentoMensalidade) {
          this.formGroup.controls.diaVencimento.disable();
        } else {
          this.listaVencimentoMensalidade$ = this.vencimentoMensalidadeService.getList();
        }
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  public onSearchClient($event: any) {
    const endpoint = $event.term.toString();

    clearTimeout(this.searchTime);

    if (endpoint.length < 3) {
      return;
    }

    this.searchClientLoading = true;

    this.searchTime = setTimeout(() => {
      this.searchClient(endpoint);
    }, 1000);
  }

  private searchClient(endpoint: string) {
    this.clienteService.getList(endpoint).subscribe(clientList => {
      this.searchClientLoading = false;

      if (clientList && clientList.length > 0) {
        this.clients.splice(0, this.clients.length);
        this.clients = clientList;
      }
    });
  }

  private getProfessionalList(): void {
    this.professionalService.getList('agenda').subscribe(
      professionalList => {
        this.listaProfissionais = professionalList;
      },
      error => {
        console.log(error.message, 'Error');
      }
    );
  }

  public getListPlanosModalidade() {
    const modalidadeId: string =  String(this.formGroup.getRawValue().modalidadeId);

    if (Number(modalidadeId) > 0) {
      this.listaPlanoModalidade$ = this.planoService.getList('modalidade/' + modalidadeId);
    }
  }

  public changeModalidade(): void {
    this.formGroup.get('planoId').setValue(0);
  }

  public changePlanoPagamento(): void {
    const planoId = Number(this.formGroup.controls.planoId.value);

    if (!this.item.plano) {
      this.item.plano = <Plano>{};
      this.item.plano.vigencia = <Vigencia>{};
    }

    const _self = this;
    const filterPlano = function (fn: Function) {
      _self.listaPlanoModalidade$.forEach(el => {
        const plano = Object.assign(el.filter(v => v.id == planoId)[0]);

        fn(plano);
      });
    };

    filterPlano((plano: Plano) => {
      this.item.plano = plano;

      this.labelDataInicio = this.item.plano.vigencia.numeroMeses == 0 ? 'Data da aula/sessão *' :
        'Data de início das aulas/sessões *';

      this.setValorPlano();

      if (!((this.params.diaFixoVencimentoMensalidade &&
        this.item.plano.vigencia.numeroMeses > 0) && !this.item.plano.utilizaAulasFixas)) {
        this.formGroup.controls.diaVencimento.disable();
      } else {
        this.formGroup.controls.diaVencimento.enable();
      }

      if (this.params.diaFixoVencimentoMensalidade && this.item.plano.vigencia.numeroMeses > 0 &&
        !this.item.plano.utilizaAulasFixas) {
        this.listaVencimentoMensalidade$ = this.vencimentoMensalidadeService.getList();
      }
    });

  }

  private setValorPlano(): void {
    this.maxParcelas = this.item.plano.vigencia.numeroMeses > 0 ? this.item.plano.vigencia.numeroMeses : 1;
    this.formGroup.controls.parcelas.setValue(this.maxParcelas, {});
    this.valorPlano = this.item.plano.valor * this.maxParcelas;
    this.setValidatorsNumeroParcelas();
  }

  public onCheckDifferentTimeOrFreeTime(): void {
    const horarioLivre = this.formGroup.controls.horarioLivre.value,
      horarioDiferente = this.formGroup.controls.horarioProfissionalDiferente.value;

    if ((horarioLivre && !horarioDiferente) || (horarioLivre && horarioDiferente)) {
      this.formGroup.controls.geralHora.disable();
      this.formGroup.controls.geralFuncionarioId.disable();

      this.clearValidatorsWeekDayCheckbox();
    }  else if (!horarioLivre && !horarioDiferente) {
      this.formGroup.controls.geralHora.enable();
      this.formGroup.controls.geralFuncionarioId.enable();

      this.clearValidatorsWeekDayCheckbox();
    } else if (!horarioLivre && horarioDiferente) {
      this.formGroup.controls.geralHora.disable();
      this.formGroup.controls.geralFuncionarioId.disable();

      this.setValidatorsWeekDayCheckbox();
    } else {
      this.formGroup.controls.geralHora.enable();
      this.formGroup.controls.geralFuncionarioId.enable();

      this.clearValidatorsWeekDayCheckbox();
    }
  }

  public onCheckWeekDay(wdCheckBox: WeekDayCheckbox): void {
    if (this.formGroup.controls[wdCheckBox.id].value && this.formGroup.controls.horarioProfissionalDiferente.value) {
      this.formGroup.controls[wdCheckBox.controlHour.id].enable();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].enable();

      this.formGroup.controls[wdCheckBox.controlHour.id]
        .setValidators([Validators.required, Validators.min(1), Validators.max(23)]);

      this.formGroup.controls[wdCheckBox.controlProfessional.id]
        .setValidators([Validators.required, Validators.min(1)]);
    } else if (this.formGroup.controls[wdCheckBox.id].value && !this.formGroup.controls.horarioProfissionalDiferente.value) {
      this.formGroup.controls[wdCheckBox.controlHour.id].enable();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].enable();

      this.formGroup.controls[wdCheckBox.controlHour.id].clearValidators();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].clearValidators();
    } else {
      this.formGroup.controls[wdCheckBox.controlHour.id].disable();
      this.formGroup.controls[wdCheckBox.controlProfessional.id].disable();
    }
  }

  public getValorMensalidade(): number {
    if (this.valorPlano <= 0 || this.formControls.parcelas.value <= 0) {
      return;
    }

    return this.valorPlano / this.formControls.parcelas.value - this.formControls.desconto.value;
  }

  private clearValidatorsWeekDayCheckbox(): void {
    this.weekDayCheckboxGroup.forEach((el) => {
      this.formGroup.controls[el.controlHour.id].disable();
      this.formGroup.controls[el.controlProfessional.id].disable();
    });
  }

  private setValidatorsWeekDayCheckbox(): void {
    this.weekDayCheckboxGroup.forEach((el) => {
      if (this.formGroup.controls[el.id].value) {
        this.formGroup.controls[el.controlHour.id].enable();
        this.formGroup.controls[el.controlHour.id]
          .setValidators([Validators.required, Validators.min(1), Validators.max(23)]);

        this.formGroup.controls[el.controlProfessional.id].enable();
        this.formGroup.controls[el.controlProfessional.id]
          .setValidators([Validators.required, Validators.min(1)]);
      }
    });
  }

  private setValidatorsNumeroParcelas(): void {
        this.formGroup.controls.parcelas.setValidators([Validators.min(1), Validators.max(this.maxParcelas)]);
  }
}
