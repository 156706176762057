import { Component, Input, OnInit } from '@angular/core';

import { Cliente } from '../../../../cliente/shared/models';
import { Plano } from '../../../../plano/shared/models';

@Component({
  selector: 'app-matricula-info-plano',
  templateUrl: './matricula-info-plano.component.html',
  styleUrls: ['./matricula-info-plano.component.scss']
})
export class MatriculaInfoPlanoComponent implements OnInit {
  @Input() public clienteAtual = {} as Cliente;
  @Input() public planoAtual = {} as Plano;

  public fotoAluno = 'assets/img/avatars/default-user.png';
  public telefoneAluno = '';

  ngOnInit() {
    if (this.clienteAtual && this.clienteAtual.id > 0 && (!!this.clienteAtual.foto && this.clienteAtual.foto.match(/^https:/i))) {
      this.fotoAluno = this.clienteAtual.foto;
    }

    if ( typeof this.clienteAtual.telefone == 'string') {
      this.telefoneAluno = this.clienteAtual.telefone;
    }

    if (typeof this.clienteAtual.telefone == 'object') {
      this.telefoneAluno = this.clienteAtual.telefone.numero;
    }
  }

}
