export interface IAgendaJustificativa {
    id: number;
    justificativa: string;
    funcionarioId: number;
    hora: number;
    data: Date;
    reagendar: boolean;
}

export class AgendaJustificativa implements IAgendaJustificativa {
    id: number;
    justificativa: string;
    funcionarioId: number;
    hora: number;
    data: Date;
    reagendar: boolean;
    constructor( id: number, justificativa: string, funcionarioId: number, hora: number, data: Date, reagendar: boolean) {
        this.id = id;
        this.justificativa = justificativa;
        this.funcionarioId = funcionarioId;
        this.hora = hora;
        this.data = data;
        this.reagendar = reagendar;
    }
}