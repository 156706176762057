import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../../../environments/environment';
import { AppSession } from '../auth/shared/models';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {
  protected api = environment.api.azure;
  protected url: string = this.api.url + 'exportar/';
  @BlockUI() blockui: NgBlockUI;

  constructor(private httpClient: HttpClient) { }

  // o parametro nome do arquivo pode ser removido caso voces consigam retornar o nome do arquivo do servidor
  public exportar(urlRelativa: string, nomePlanilha: string) {
    const url = this.url + urlRelativa;
    this.blockui.start();

    this.httpClient.get(url, {
      headers: {
        "Authorization": this.getAuthToken(),
      },
      observe: 'response',
      responseType: 'arraybuffer'
    }).subscribe(response => {
      let planilha = document.createElement("a");
      let fileName = nomePlanilha + ".xlsx";
      document.body.appendChild(planilha);

      let json = JSON.stringify(response.body),
        blob = new Blob([response.body], { type: response.headers.get('content-type') }),
        file = new File([blob], fileName, { type: response.headers.get('content-type') }),
        url = window.URL.createObjectURL(file);

      planilha.href = url;
      planilha.download = fileName;
      planilha.click(); 
      this.blockui.stop();
      window.URL.revokeObjectURL(url);
    }, err => {
      this.blockui.stop();
      //console.log('nao deu certoooooooooooooo!!!!!!!!!!', err);
      // apresentar uma mensagem de erro no padrão do sistema
    });
  }

  private getAuthToken() {
    const sessionData = this.getNavigatorSessionData();
    return `Bearer ${sessionData.access_token}`;
  }
  protected getNavigatorSessionData() {
    const accessToken = this.api.authToken + '-' + this.api.enviroment;
    const sessionData: AppSession = Object.assign({}, JSON.parse(window.localStorage.getItem(accessToken)));
    return sessionData;
  }
}
