import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../shared';

import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})

export class PasswordResetComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;

  public recoverCode: string;
  private hash: string;
  public password: string;
  public confirmPassword: string;

  private showPassword: boolean;

  constructor(
    public toastrService: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    protected injector: Injector,
    protected authService: AuthService,
    public title: Title,
  ) {
    this.showPassword = false;
  }

  ngOnInit(): void {
    this.title.setTitle("Código de Recuperação");

    this.activeRoute.queryParams
      .subscribe(params => {
        console.log(params);
        this.hash = params.hash;
        this.recoverCode = params.recoverCode;
      })
  }

  public validatePassword() {
    const credentials = {
      codigo: this.recoverCode,
      hash: this.hash,
      password: this.password,
      confirmPassword: this.confirmPassword,
    }

    if (!credentials.password || !credentials.confirmPassword) {
      this.toastrService.error("As senhas devem ser preenchidas")
      return
    }

    if (credentials.password.length < 6) {
      this.toastrService.error("A senha deve possuir de 6 a 20 caracteres")
      return
    }
    if (credentials.password.length > 20) {
      this.toastrService.error("A senha deve possuir de 6 a 20 caracteres")
      return
    }
    if (credentials.password != credentials.confirmPassword) {
      this.toastrService.error("A Nova Senha e a Confirmação não combinam");
      return
    }

    this.blockui.start();

    this.authService.validateNewPasswordRecovery(credentials.codigo, credentials.hash, credentials.password, credentials.confirmPassword)
      .pipe(finalize(this.blockui.stop))
      .subscribe({
        next: () => {
          this.blockui.start()
          this.router.navigate(
            ['/', 'auth', 'login']
          )
        }, error: (response) => {
          console.log(response);
        }
      })

    this.blockui.stop();
  }
}
