import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CoreService } from '../../../shared/core.service';

import { Group } from '../models';
import { Recurso } from '../../../auth/shared/models';

@Injectable()
export class UserGroupService extends CoreService<Group> {
    protected url = `${this.api.url}grupos/` as string;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    public buscarRecursos(): Observable<Recurso[]> {
        return this.http.get<Recurso[]>(`${this.api.url}recursos`, this.getHeaders())
        .pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

    public buscarGrupos(empresaId?: string): Observable<Group[]> {
        const url = empresaId ? `${this.api.url}usuarios/grupos/${empresaId}` : `${this.api.url}usuarios/grupos`;
        return this.http.get<Group[]>(url, this.getHeaders())
        .pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

}
