import { Component, OnInit, Injector } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListComponent } from '../../../../shared/components';

import { RelatorioComissaoService } from '../../services';
import { FechamentoComissao } from '../../models';

@Component({
  selector: 'app-dialog-lista-aula-comissao',
  templateUrl: './dialog-lista-aula-comissao.component.html',
  styleUrls: ['./dialog-lista-aula-comissao.component.scss']
})
export class DialogListaAulaComissaoComponent extends ListComponent<FechamentoComissao> implements OnInit {
  public static resourceName = 'RelatorioComissao';
  public comissao: FechamentoComissao = <FechamentoComissao>{};

  public dialogTitle: string;

  constructor(
    protected injector: Injector,
    protected service: RelatorioComissaoService,
    public modal: BsModalRef
  ) {
    super(injector, service);
  }

  ngOnInit() {
    this.dialogTitle = 'Relação de aulas - ' + this.comissao.nomeFuncionario;

    const endpoint = 'aulas/' + this.comissao.id.toString();
    super.loadList(endpoint);
  }

}
