import { Injectable, Injector } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CoreService } from '../../shared/core.service';
import { Modalidade } from './models';

@Injectable()
export class ModalidadeService extends CoreService<Modalidade> {
  protected url: string = this.api.url + 'modalidades/';

  private _currentModalidade: BehaviorSubject<Modalidade> = new BehaviorSubject(null);
  public currentModalidade: Observable<Modalidade> = this._currentModalidade.asObservable();

  private _listModalidades: BehaviorSubject<Modalidade[]> = new BehaviorSubject(null);
  public listModalidades: Observable<Modalidade[]> = this._listModalidades.asObservable();

  constructor(injector: Injector) {
    super(injector);
  }

  public setCurrentModalidade(modalidade?: Modalidade) {
    this._currentModalidade.next(modalidade);
  }

  public getById(id: number): Observable<Modalidade> {
    return this.http.get<Modalidade>(this.url + id, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public getList(endpoint?: string): Observable<Modalidade[]> {
    const url = endpoint ? this.url + endpoint : this.url;

    return this.http.get<Modalidade[]>(url, this.getHeaders())
    .pipe(
        map(data => {
          this._listModalidades.next(data);
          return data;
        }),
        catchError(this.handleError)
    );
  }

}
