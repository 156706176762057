import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { ListComponent } from '../../../../shared/components';
import { MatriculaDetalhesComponent } from '../matricula-detalhes';
import { NovaMatriculaComponent } from '../nova-matricula/nova-matricula.component';

import { Matricula } from '../../models';
import { Cliente } from '../../../../cliente/shared/models';

import { ClienteService } from '../../../../cliente/shared';
import { MatriculaService } from '../../services';
import { finalize } from 'rxjs/operators';
import { DownloadFilesService } from '../../../../shared/download-files.service';


@Component({
  selector: 'app-lista-matriculas-cliente',
  templateUrl: './lista-matriculas-cliente.component.html',
  styleUrls: ['./lista-matriculas-cliente.component.scss']
})
export class ListaMatriculasClienteComponent extends ListComponent<Matricula> implements OnInit, OnDestroy {
  public static resourceName = 'Matricula';

  public curCliente: Cliente;

  private curClienteSubscription: Subscription = new Subscription();
  private routeSubscription: Subscription = new Subscription();


  constructor(
    protected injector: Injector,
    protected service: MatriculaService,
    protected route: ActivatedRoute,
    private clienteService: ClienteService,
    private downloadService : DownloadFilesService
  ) {
    super(injector, service);

    this.baseListConfig.title = 'Matrículas do Aluno';
    this.baseListConfig.icon = 'fa fa-list-alt';
    this.baseListConfig.addButtonIsShown = true;
    this.baseListConfig.exportExcelButtonIsShown = true;
    this.baseListConfig.exportExcelButtonTitle = "Exportar Matriculas";
    this.baseListConfig.exportExcelFunction = this.getPlanilhaMatricula;
  }

  ngOnInit() {

    if (this.baseListConfig.title == 'Matrículas do Aluno') {
      this.baseListConfig.exportExcelButtonIsShown = null;
      this.baseListConfig.exportExcelButtonTitle = null;
      this.baseListConfig.exportExcelFunction = null;
    }

    this.curClienteSubscription = this.clienteService.getCurItem()
    .subscribe(cliente => {
      if (cliente && cliente.id > 0) {
        this.curCliente = cliente;

        this.loadList();
      }

    });

    this.curItemSubscription = this.service.getCurItem().subscribe(entity => {
      if (entity) {
        this.curItem = entity;
      }
    });

    this.actionSubscription = this.service.action.subscribe(action => {
      if (action && action.match(/delete|update/g)) {
        if (action == 'delete') {
          this.service.setCurItem(null);
        }

        if (action == 'update' && this.curItem && this.curItem.aluno) {
          const tempItem = this.listOfItem.filter(el => el.id == this.curItem.id);

          if (tempItem.length == 0) {
            this.openItemDialog(this.curItem);
          }
        }

        this.loadList();
      }
    });

    this.routeSubscription = this.route.params.subscribe(params => {
      if (params['exibir'] == 'detalhes' && params['id'] > 0) {
        this.blockui.start();

        this.service.get(params['id'].toString())
        .pipe(finalize(this.blockui.stop))
        .subscribe(matricula => {
          if (!matricula) {
            return;
          }

          this.openItemDialog(matricula);
        });
      }
    });

  }

  public loadList() {
    if (!this.curCliente) {
      return;
    }

    const endpoint = 'aluno/' + this.curCliente.id.toString();
    super.loadList(endpoint);
  }

  public openItemDialog(entity?: Matricula) {
    if ((!entity || !entity.id) && !this.permissions.inserir) {
        this.toastr.warning('Você não tem permissão para Inserir uma matrícula.', 'Atenção');
        return;
    }

    if (!entity) {
      this.modalService.show(NovaMatriculaComponent, {
        class: 'modal-md',
        backdrop: 'static',
        keyboard: false,
        initialState: {
          clienteAtual: this.curCliente
        }
      });

      return;
    }

    this.modalService.show(MatriculaDetalhesComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-h80',
      animated: false,
      initialState: {
        item: entity
      }
    });
  }

  ngOnDestroy() {
    this.curClienteSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.service.setAction(null);
    this.service.setCurItem(null);
    super.ngOnDestroy();
  }

  public getPlanilhaMatricula() {
    const urlRelativa = `excel-aulas-por-aluno/${this.curCliente.id}`;
    this.downloadService.exportar(urlRelativa, "PlanilhaMatriculas");
  }

}
