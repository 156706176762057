import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IMensalidade, ReciboMensalidade } from '../models';
import { FormaPagamento } from '../../../configuracoes/pagamento/shared/models';

import { CoreService } from '../../../shared/core.service';

@Injectable()
export class MensalidadeService extends CoreService<IMensalidade> {
    protected url: string = this.api.url + 'mensalidades/';

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    public buscarRecibo(endpoint: string): Observable<ReciboMensalidade> {

        return this.http.get<ReciboMensalidade>(`${this.url}${endpoint}`, this.getHeaders())
        .pipe(
            map(recibo => recibo),
            catchError(this.handleError)
        );
    }

    public paymentMethods(): Observable<FormaPagamento[]> {
        const url = `${this.url}formasPagamento`;

        return this.http.get<FormaPagamento[]>(url, this.getHeaders()).pipe(
          map(data => data),
          catchError(this.handleError)
        );
    }
}
