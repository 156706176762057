import { GuidedTour, Orientation } from 'ngx-guided-tour';

export const layoutTour: GuidedTour = {
    tourId: 'layout-tour',
    skipCallback: () => {
        localStorage.setItem('tourSkip', 'true');
    },
    steps: [
        {
            selector: '.nav-item-inicio',
            content: 'Utilize quando quiser navegar para a primeira tela e ver o RESUMO das principais informações.',
            orientation: Orientation.Top
        },
        {
            selector: '.nav-item-agenda',
            content: 'Selecione essa oção para visualizar as aulas agendadas para os seus clientes.',
            orientation: Orientation.Top
        },
        {
            selector: '.nav-item-evolucoes',
            content: 'Recurso obrigatório segundo o conselho de Fisioterapia. Utilize para fazer a evolução de cada aluno.',
            orientation: Orientation.Top
        },
        {
            selector: '.nav-item-matriculas',
            content: 'Lista com todos os alunos matrículados (Ativos, Cancelados, Trancados ou Finalizados)',
            orientation: Orientation.Top
        },
        {
            selector: '.nav-item-financeiro',
            content: 'Acompanhe por aqui a saúde do seu negócio. (Contas Pagar, Receber, Comissões)',
            orientation: Orientation.Top,
            action: () => {
                actionDropDown('.nav-item-financeiro');
            },
        },
        {
            selector: '.nav-item-cadastros',
            content: 'Primeiro passo para controlar o seu negócio. Ajuste os cadastros para começar.',
            orientation: Orientation.Top,
            action: () => {
                actionDropDown('.nav-item-cadastros');
            },
        },
        {
            selector: '.nav-item-relatorios',
            content: 'Venha aqui sempre que quiser consultar informações importântes, para tomada de decisão.',
            orientation: Orientation.Top,
            action: () => {
                actionDropDown('.nav-item-relatorios');
            },
        },
    ]
};

const agendaTour: GuidedTour = {
    tourId: 'agendaTour',
    steps: [
        {
            selector: '.agenda-btn-add',
            content: 'Utilize para inserir uma nova matrícula ou um agendamento.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.agenda-btn-refresh',
            content: 'Utilizado para atualizar a agenda e verificar se existem alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.agenda-form-modalidade',
            content: 'Aqui você seleciona qual agenda quer ver. Lembrando que você pode controlar mais de uma modalidade.',
            orientation: Orientation.Top
        },
        {
            selector: '.agenda-btn-group-navigation',
            content: 'Opções de navegação para avançar ou voltar os dias na agenda.',
            orientation: Orientation.BottomRight
        },
    ]
};

const matriclasTour: GuidedTour = {
    tourId: 'matriculasTour',
    steps: [
        {
            selector: '.matriculas-input-pesquisa',
            content: 'Você pode pesquisar por um aluno específico.',
            orientation: Orientation.Bottom
        },
        {
            selector: '.matriculas-select-status',
            content: 'Filtre também o status atual da matrícula.',
            orientation: Orientation.Bottom
        },
        {
            selector: '.list-btn-add',
            content: 'Para inserir uma nova matrícula.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'E aqui, para atualizar os dados.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'As matrículas vão aprecer aqui. Você pode clicar sobre a linha para ver mais detalhes.',
            orientation: Orientation.Top
        },
    ]
};

const evolucaoTour: GuidedTour = {
    tourId: 'evolucao-tour',
    steps: [
        {
            selector: '.evolucao-select-aluno',
            content: 'Aqui você pode buscar um aluno específico.',
            orientation: Orientation.Top
        },
        {
            selector: '.evolucao-select-profissional',
            content: 'E aqui, você pode filtrar o profissional que ministrou a aula/atendimento.',
            orientation: Orientation.Top
        },
        {
            selector: '.evolucao-data-range',
            content: 'Informe o intervalo de datas e clique no botão para buscar as evoluções.',
            orientation: Orientation.Top
        },

    ]
};

const mensalidadesTour: GuidedTour = {
    tourId: 'mensalidades-tour',
    steps: [
        {
            selector: '.mensalidades-tour-filtro',
            content: 'Utilize as oções de filtro para encontrar o registro desejado.',
            orientation: Orientation.Top
        },
        {
            selector: '.mensalidades-tour-resumo',
            content: 'Aqui você pode ver o resumo das mensalidades, de acordo com o filtro.',
            orientation: Orientation.Top
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'As mensalidades vão aparecer aqui. Você pode clicar sobre ela para fazer a baixa ou imprimir o recibo.',
            orientation: Orientation.Top
        },
    ]
};

const contasPagarTour: GuidedTour = {
    tourId: 'contas-pagar-tour',
    steps: [
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona novas contas a serem pagas.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.contas-pagar-tour-filtro',
            content: 'Utilize as opções de filtro para encontrar o registro desejado.',
            orientation: Orientation.Top
        },
        {
            selector: '.contas-pagar-tour-resumo',
            content: 'Aqui você pode ver o resumo das contas a serem pagas, de acordo com o filtro.',
            orientation: Orientation.Top
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'As contas a pagar aparecem aqui. Clique sobre ela para editar ou imprimir o recibo.',
            orientation: Orientation.Top
        },
    ]
};

const fechamentoComissaoTour: GuidedTour = {
    tourId: 'fechamento-comissao-tour',
    steps: [
        {
            selector: '.fechamento-comissao-data',
            content: 'Escolha o dia para fazer o fechamento.',
            orientation: Orientation.Top
        },
        {
            selector: '.fechamento-comissao-select-profissional',
            content: 'Você pode fazer o fechamento de todos os profissinais, ou de um específico.',
            orientation: Orientation.Top
        },
        {
            selector: '.fechamento-comissao-btn-calcular',
            content: 'Agora é só calcular a comissão.',
            orientation: Orientation.Top
        }
    ]
};

const cadastroClienteTour: GuidedTour = {
    tourId: 'cadastro-cliente-tour',
    steps: [
        {
            selector: '.cadastro-cliente-form-group-pesquisa',
            content: 'Encontre o Cliente buscando pelo nome.',
            orientation: Orientation.Bottom
        },
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona um novo cliente.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'Clique no Cliente desejado para ver mais informações sobre ele.',
            orientation: Orientation.Top
        },
    ]
};

const cadastroProfissionalTour: GuidedTour = {
    tourId: 'cadastro-profissional-tour',
    steps: [
        {
            selector: '.cadastro-profissional-form-group-pesquisa',
            content: 'Encontre o profissional pelo nome.',
            orientation: Orientation.Bottom
        },
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona um novo Profissional.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'Clique sobre a linha para ver mais informações do Profissional.',
            orientation: Orientation.Top
        },
    ]
};

const cadastroCargoTour: GuidedTour = {
    tourId: 'cadastro-cargo-tour',
    steps: [
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona um movo Cargo.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'Clique na linha para editar o Cargo.',
            orientation: Orientation.Top
        },
    ]
};

const cadastroModalidadeTour: GuidedTour = {
    tourId: 'cadastro-modalidade-tour',
    steps: [
        {
            selector: '.cadastro-modalidade-input-pesquisa',
            content: 'Encontre a Modalidade pelo nome.',
            orientation: Orientation.Bottom
        },
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona uma nova Modalidade.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'Clique sobre a linha para editar a Modalidade.',
            orientation: Orientation.Top
        },
    ]
};

const cadastroPlanoTour: GuidedTour = {
    tourId: 'cadastro-plano-tour',
    steps: [
        {
            selector: '.cadastro-plano-form-group-pesquisa',
            content: 'Aqui você busca o Plano pelo nome, ou pode buscar todos os Planos de uma Modalidade.',
            orientation: Orientation.Bottom
        },
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona um novo Plano.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'Clique na linha para editar o Plano desejado.',
            orientation: Orientation.Top
        },
    ]
};

const cadastroFeriadoTour: GuidedTour = {
    tourId: 'cadastro-feriado-tour',
    steps: [
        {
            selector: '.list-btn-add',
            content: 'Aqui você adiciona um novo Feriado.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: 'div.table-responsive > table.table tbody > tr',
            content: 'Clique na linha para editar um Feriado.',
            orientation: Orientation.Top
        },
    ]
};

const relatorioAulaTour: GuidedTour = {
    tourId: 'relatorio-aula-tour',
    steps: [
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-print',
            content: 'Aqui você imprime o relatório.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.relatorio-aula-tour-filtro',
            content: 'Utilize as opções de filtro para encontar o registro desejado.',
            orientation: Orientation.Top
        },
    ]
};

const relatorioMatriculaTour: GuidedTour = {
    tourId: 'relatorio-matricula-tour',
    steps: [
        {
            selector: '.list-btn-refresh',
            content: 'Utilize para atualizar em busca de alterações pendentes.',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.list-btn-print',
            content: 'Aqui você imprime o relatório',
            orientation: Orientation.BottomRight
        },
        {
            selector: '.relatorio-matricula-tour-filtro',
            content: 'Utilize as opções de filtro para encontrar o registro desejado.',
            orientation: Orientation.Top
        },
    ]
};

const relatorioComissaoTour: GuidedTour = {
    tourId: 'relatorio-comissao-tour',
    steps: [
        {
            selector: '.relatorio-comissao-tour-filtro',
            content: 'Utilize as oções de filtro para encontrar comissões que já foram fechadas.',
            orientation: Orientation.Top
        },
    ]
};

export const tourRouteConfig: {[key: string]: GuidedTour} = {
    '/agenda': agendaTour,
    '/matriculas/lista-por-aluno': matriclasTour,
    '/avaliacao/evolucao/lista': evolucaoTour,
    '/financeiro/mensalidades/lista': mensalidadesTour,
    '/cliente/lista': cadastroClienteTour,
    '/profissional/lista': cadastroProfissionalTour,
    '/cargo/lista': cadastroCargoTour,
    '/modalidade/lista': cadastroModalidadeTour,
    '/plano/lista': cadastroPlanoTour,
    '/feriado/lista': cadastroFeriadoTour,
    '/agenda/relatorios/aula': relatorioAulaTour,
    '/matriculas/relatorio': relatorioMatriculaTour,
    '/financeiro/comissao/por-profissional': relatorioComissaoTour,
    '/financeiro/contas-pagar/lista': contasPagarTour,
    '/financeiro/comissao/fechamento-periodo': fechamentoComissaoTour,
};

function actionDropDown(dropDownmenuClass: string): void {
    document.querySelector(dropDownmenuClass).classList.add('open');
    setTimeout(() => {
        document.querySelector(dropDownmenuClass).classList.remove('open');
    }, 500);
}
