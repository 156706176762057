import { Component, OnInit, Input } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { ListConfig } from '../../models';

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss']
})
export class BaseListComponent implements OnInit {
  @Input() parent: any;
  @Input() config: ListConfig;

  constructor(
    private _localeService: BsLocaleService,
    public modalService: BsModalService
  ) {}

  ngOnInit() {
    this._localeService.use('pt-br');
  }

}
