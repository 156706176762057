export interface WeekDayCheckbox {
    id: string;
    name: string;
    label: string;
    controlHour: {
      id: string;
      name: string;
      label: string;
    };
    controlProfessional: {
      id: string;
      name: string;
      label: string;
    };
}

export const weekDayCheckboxGroup: WeekDayCheckbox[] = [
   {
     id: 'dom',
     name: 'domingo',
     label: 'Domingo',
     controlHour: {
      id: 'domHora',
      name: 'horaDomingo',
      label: 'Horário de Domingo'
    },
     controlProfessional: {
      id: 'domFuncionarioId',
      name: 'funcionarioDom',
      label: 'Profissional de Domingo'
     }
   },
   {
     id: 'seg',
     name: 'segunda',
     label: 'Segunda',
     controlHour: {
      id: 'segHora',
      name: 'horaSegunda',
      label: 'Horário de Segunda'
    },
     controlProfessional: {
      id: 'segFuncionarioId',
      name: 'funcionarioSeg',
      label: 'Profissional de Segunda'
     }
   },
   {
     id: 'ter',
     name: 'terca',
     label: 'Terça',
     controlHour: {
      id: 'terHora',
      name: 'horaTerca',
      label: 'Horário de Terça'
    },
     controlProfessional: {
      id: 'terFuncionarioId',
      name: 'funcionarioTer',
      label: 'Profissional de Terça'
     }
   },
   {
     id: 'qua',
     name: 'quarta',
     label: 'Quarta',
     controlHour: {
      id: 'quaHora',
      name: 'horaQuarta',
      label: 'Horário de Quarta'
    },
     controlProfessional: {
      id: 'quaFuncionarioId',
      name: 'funcionarioQua',
      label: 'Profissional de Quarta'
     }
   },
   {
     id: 'qui',
     name: 'quinta',
     label: 'Quinta',
     controlHour: {
      id: 'quiHora',
      name: 'horaQuinta',
      label: 'Horário de Quinta'
    },
     controlProfessional: {
      id: 'quiFuncionarioId',
      name: 'funcionarioQui',
      label: 'Profissional de Quinta'
     }
   },
   {
     id: 'sex',
     name: 'sexta',
     label: 'Sexta',
     controlHour: {
      id: 'sexHora',
      name: 'horaSexta',
      label: 'Horário de Sexta'
    },
     controlProfessional: {
      id: 'sexFuncionarioId',
      name: 'funcionarioSex',
      label: 'Profissional de Sexta'
     }
   },
   {
     id: 'sab',
     name: 'sabado',
     label: 'Sábado',
     controlHour: {
      id: 'sabHora',
      name: 'horaSabado',
      label: 'Horário de Sábado'
    },
     controlProfessional: {
      id: 'sabFuncionarioId',
      name: 'funcionarioSab',
      label: 'Profissional de Sábado'
     }
   },
];
