import { OnInit, Injector, OnDestroy, Directive } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { ToastrService } from 'ngx-toastr';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

import { CoreService, IEntity } from '../core.service';
import { AuthService } from '../../auth/shared';

import { Permissoes } from '../../auth/shared/models';
import { ModalWindowConfirmComponent } from './modal-window-confirm/modal-window-confirm.component';
import { ModalBloqueioPlanosComponent } from './modal-bloqueio-planos/modal-bloqueio-planos.component';

@Directive()
export abstract class EditDirective<T extends IEntity> implements OnInit, OnDestroy {
    @BlockUI() blockui: NgBlockUI;
    public modal: BsModalRef;
    protected toastr: ToastrService;
    protected _localeService: any;
    protected router: Router;
    protected modalService: BsModalService;
    protected authService: AuthService;

    public permissions: Permissoes;
    public saveButton: boolean;
    public saveButtonLabel = 'Salvar';
    public deleteButton: boolean;
    public deleteButtonLabel = 'Excluir';
    public deleteButtonTitle = 'Excluir Item';
    public deleteButtonIcon = 'fa fa-trash';
    public showFooter = true;
    public showCloseButton = false;

    public tituloConfirmacaoExclusao = 'Excluir registro';
    public mensagemConfirmacaoExclusao = 'Tem certeza que deseja Excluir o registro?';

    public item: T = <T>{};
    public submitted: boolean;
    public formGroup: UntypedFormGroup;
    public formControls: { [key: string]: UntypedFormControl | UntypedFormGroup | UntypedFormArray };

    protected routerEventSubs: Subscription = new Subscription();
    protected curItemSub: Subscription = new Subscription();

    constructor(injector: Injector, protected service: CoreService<T>) {
        this.modal = injector.get(BsModalRef);
        this.toastr = injector.get(ToastrService);
        this._localeService = injector.get(BsLocaleService);
        this.router = injector.get(Router);
        this.modalService = injector.get(BsModalService);
        this.authService = injector.get(AuthService);

        this.permissions = this.authService.getPermissions(this.constructor['resourceName']);
    }

    ngOnInit() {
        this._localeService.use('pt-br');
        this.initFormControls();

        if (this.formControls) {
            this.formGroup = new UntypedFormGroup(Object.assign(this.formControls));
        }

        this.curItemSub = this.service.getCurItem().subscribe(curItem => {
            if (curItem) {
                this.item = curItem;
                this.patchFormGroup();
            }
        });

        if (this.item && this.item.id > 0) {
            this.loadItem();
        }

        this.routerEventSubs = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.modal.hide();
            }
        });
    }

    ngOnDestroy() {
        this.service.setCurItem(null);
        this.curItemSub.unsubscribe();
        this.routerEventSubs.unsubscribe();
    }

    initFormControls() { }

    validateField(field: string, nested?: boolean, parentControl?: string) {
        if (nested) {
            return this.formGroup['controls'][parentControl]['controls'][field].invalid &&
                (this.formGroup['controls'][parentControl]['controls'][field].touched || this.submitted);
        }

        return this.formGroup.controls[field].invalid && (this.formGroup.controls[field].touched || this.submitted);
    }

    loadItem(partialRoute?: string) {
        this.blockui.start();
        const url = partialRoute ? String(this.item.id) + '/' + partialRoute : String(this.item.id);

        this.service.get(url)
            .pipe(finalize(this.blockui.stop))
            .subscribe(
                entity => {
                    this.item = entity;
                },
                error => {
                    this.toastr.error(error.message);
                }
            );
    }

    close() {
        this.modal.hide();
    }

    cancel() {
        if (this.item.id) {
            this.formGroup.patchValue(this.item);
        } else {
            this.formGroup.reset();
        }
    }

    save() {
        this.submitted = true;

        if (this.formGroup.valid) {
            const item = this.formGroup.getRawValue() as T;
            const message = this.item.id ? 'Registro alterado com sucesso.' : 'Registro criado com sucesso.';

            if (item.id && !this.permissions.atualizar) {
                this.toastr.warning('Você não tem permissão para Editar.', 'Atenção');
                return;
            }

            if (!item.id && !this.permissions.inserir) {
                this.toastr.warning('Você não tem permissão para Inserir.', 'Atenção');
                return;
            }

            this.blockui.start();

            this.service.save(item)
                .pipe(finalize(this.blockui.stop))
                .subscribe(
                    () => {
                        this.toastr.success(message);
                        this.modal.hide();
                    },
                  error => {
                    if (error.type == 'modal') {
                            this.modal.hide();
                            this.modalService.show(ModalBloqueioPlanosComponent, {
                                class: 'modal-body modal-sm',
                                backdrop: 'static',
                                initialState: {
                                    tituloModal: error.title,
                                    textModal: error.message,
                                }
                            });
                            this.blockui.stop();
                        }
                        else {
                            this.toastr.error(error.message);
                        }
                    });
        } else {
            this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
        }
    }

    delete() {
        if (!this.permissions.excluir) {
            this.toastr.warning('Você não tem permissão para Excluir.', 'Atenção');
            return;
        }

        this.modalService.show(ModalWindowConfirmComponent, {
            class: 'modal-md',
            backdrop: 'static',
            keyboard: false,
            initialState: {
                dialogTitle: this.tituloConfirmacaoExclusao,
                contentText: this.mensagemConfirmacaoExclusao
            }
        });

        const modalOnHiddenSub = this.modalService.onHidden.subscribe((res: string) => {
            if (res == 'true') {
                this.blockui.start();
                this.service.delete(this.item.id)
                    .pipe(finalize(this.blockui.stop))
                    .subscribe(
                        () => {
                            this.toastr.success('Registro excluído.');
                            this.modal.hide();
                        },
                        error => {
                            this.toastr.error(error.message);
                        }
                    );
            }

            modalOnHiddenSub.unsubscribe();
        });

    }

    protected patchFormGroup(): void {
        if (this.formGroup instanceof UntypedFormGroup) {
            this.formGroup.patchValue(this.item);
        }
    }

    public customDateAgenda(numero?: number): Date {
        if (!numero) {
            return;
        }

        if (typeof numero !== 'number') {
            return numero;
        }

        const numeroString = numero.toString();
        const ano = Number(numeroString.substr(0, 4));
        const mes = Number(numeroString.substr(4, 2));
        const dia = Number(numeroString.substr(6, 2));

        return new Date(ano, mes - 1, dia, 0, 0);
    }

}
