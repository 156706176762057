import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefoneBr'
})
export class TelefoneBrPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      value = value.toString();
      const lengthOfValue: number = value.length;

      switch (lengthOfValue) {
        case 11:
          return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 11);
        case 10:
          return '(' + value.substring(0, 2).concat(') ')
            .concat(value.substring(2, 6))
            .concat('-')
            .concat(value.substring(6, 10));
        default:
          return value;
      }
    }
    return value;
  }

}
