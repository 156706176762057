import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Assinatura, AssinaturaStatus } from './models';

import { CoreService } from '../../shared/core.service';
import { AssinaturaCriar } from './models/assinatura';

@Injectable()
export class AssinaturaService extends CoreService<Assinatura> {
    protected url = `${this.api.url}assinaturas/`;

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    public buscarStatus(): Observable<AssinaturaStatus> {
        return this.http.get<AssinaturaStatus>(`${this.url}status`, this.getHeaders())
            .pipe(
                map(status => status),
                catchError(this.handleError)
            );
    }

    public criarAssinatura(entidade: AssinaturaCriar) {
        return this.http.post<AssinaturaCriar>(`${this.url}`, entidade)
            .pipe(
                map(status => status),
                catchError(this.handleError)
            );
    }
}
