import { Component, Input, Injector, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Matricula } from '../../../../matriculas/shared/models';
import { ListComponent, ModalWindowConfirmComponent } from '../../../../shared/components';
import { LoggedUser } from '../../../../user/shared/models';

import { Reposicao } from '../../models';
import { ReposicaoService } from '../../reposicao.service';
import { UserService } from '../../../../user/shared';

@Component({
  selector: 'app-lista-reposicao-matricula',
  templateUrl: './lista-reposicao-matricula.component.html',
  styleUrls: ['./lista-reposicao-matricula.component.scss']
})
export class ListaReposicaoMatriculaComponent extends ListComponent<Reposicao> implements OnInit, OnDestroy {
  public static resourceName = 'Reposicao';
  @Input() matricula: Matricula;

  public loggedUser: LoggedUser = <LoggedUser>{};

  private loggedUserSub: Subscription = new Subscription();

  constructor(
    protected injector: Injector,
    protected service: ReposicaoService,
    private userService: UserService
  ) {
    super(injector, service);

    this.baseListConfig.title = 'Reposições em Aberto';
    this.baseListConfig.icon = 'fa fa-calendar-o';

    this.baseListConfig.pagination.itemsPerPage = 500;
  }

  ngOnInit() {
    this.userService.loggedUser.subscribe(user => {
      this.loggedUser = user;
    });

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.loggedUserSub.unsubscribe();

    super.ngOnDestroy();
  }

  loadList() {
    const endpoint = 'matricula/' + String(this.matricula.id);
    super.loadList(endpoint);
  }

  public openDialogCancel(reposicao: Reposicao) {
    if (!this.permissions.excluir) {
      this.toastr.warning('Você não tem permissão para Cancelar reposição.', 'Atenção');
      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-app-secondary',
      backdrop: 'static',
      initialState: {
        dialogTitle: 'Atenção',
        contentText: 'Tem certeza que deseja cancelar esta reposição?'
      }
    });

    const modalSub = this.modalService.onHidden.subscribe(action => {
      if (action && action == 'true') {
        const reposicoes = [reposicao.id];
        this.cancelReposicao(reposicoes);
      }

      modalSub.unsubscribe();
    });
  }

  public cancelReposicao(ids: number[]) {
    this.blockui.start();

    const successMessage = ids.length > 1 ? 'As reposições foram canceladas com sucesso!' :
     'Reposição cancelada com sucesso!';

    this.service.bulkCancel(ids)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      (data) => {
        data.forEach(cancelled => {
          this.listOfItem.map(
            el => el.cancelado = (el.id == cancelled.reposicaoId && cancelled.cancelado) ? true : el.cancelado);

          this.listPartialOfItems.map(
            el2 => el2.cancelado = (el2.id == cancelled.reposicaoId && cancelled.cancelado) ? true : el2.cancelado);
        });

        this.toastr.success(successMessage);
      },
      error => {
        this.toastr.error(error.message, 'Erro');
      }
    );
  }

}
