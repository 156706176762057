import { Component, Injector, OnInit } from '@angular/core';

import { MensalidadeDetalhesBaseDirective } from './mensalidade-detalhes-base.directive';

import { MensalidadeAlunoService } from '../../services';

@Component({
  selector: 'app-mensalidade-detalhes-aluno',
  templateUrl: './mensalidade-detalhes-layout.component.html',
  styleUrls: ['./mensalidade-detalhes-layout.component.scss']
})
export class MensalidadeDetalhesAlunoComponent extends MensalidadeDetalhesBaseDirective implements OnInit {
  public static resourceName = 'MensalidadeAluno';

  constructor(
    protected injector: Injector,
    protected service: MensalidadeAlunoService,
  ) {
    super(injector, service);
  }

  public printInvoice(): void {
    super.printInvoice('MensalidadeAluno');
  }

}
