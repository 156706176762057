import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable, Subscription, } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { WizardComponent } from 'angular-archwizard';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ChangeListItemComponent, EditDirective, ModalWindowConfirmComponent } from '../../../../shared/components';

import { Cliente } from '../../../../cliente/shared/models';
import { Matricula, WeekDayCheckbox, weekDayCheckboxGroup } from '../../models';
import { Modalidade } from '../../../../modalidades/shared/models';
import { Plano, Vigencia } from '../../../../plano/shared/models';
import { Param } from '../../../../param/shared';
import { FormaPagamento } from '../../../../configuracoes/pagamento/shared/models';
import { IMensalidade, MensalidadeMatriculaArgs } from '../../../../financeiro/shared/models';
import { Professional } from '../../../../professional/shared/models';
import { ArgsUpdateInvoiceValues } from '../../../../financeiro/shared/models/iMensalidade';

import { MatriculaService } from '../../services';
import { ModalidadeService } from '../../../../modalidades/shared';
import { PlanoService } from '../../../../plano/shared';
import { ProfessionalService } from '../../../../professional/shared';
import { AgendaService } from '../../../../agenda/shared/services';

@Component({
  selector: 'app-nova-matricula',
  templateUrl: './nova-matricula.component.html',
  styleUrls: ['./nova-matricula.component.scss']
})
export class NovaMatriculaComponent extends EditDirective<Matricula> implements OnInit, OnDestroy {
  static resourceName = 'Matricula';
  @ViewChild(WizardComponent) public matricularWizard: WizardComponent;
  @ViewChild('weekDayControl') public weekDayControl: NgSelectComponent;

  public dialogTitle = 'Nova matrícula';

  public params = {} as Param;
  public clients = [<Cliente>{}] as Cliente[];
  public searchClientLoading: boolean;
  public clienteAtual = {} as Cliente;
  public planoAtual = {} as Plano;
  public labelDataInicio: string;
  public listaMensalidades = [] as IMensalidade[];
  public weekDaysCheckboxGroup = [] as WeekDayCheckbox[];
  public messageInvalidWeekday = '';

  public listaModalidade$: Observable<Modalidade[]>;
  public listaPlanoModalidade = [] as Plano[];
  public listaFormaPagamento = [] as FormaPagamento[];
  public workingHours: {hora: number, descricao: string}[] = [];
  public professionalList: Professional[] = [];

  private searchTime;
  private calculatedInvoices: boolean;
  public planPeddingChange: boolean;
  private descontoPlano = 0;
  private parcelasPlano = 1;

  private changeDescontoSub: Subscription = new Subscription();
  private changeParcelasSub: Subscription = new Subscription();

  constructor(
    protected injector: Injector,
    protected service: MatriculaService,
    private modalidadeService: ModalidadeService,
    private planoService: PlanoService,
    private professionalService: ProfessionalService,
    private agendaService: AgendaService
  ) {
    super(injector, service);

    this.planoAtual.vigencia = {} as Vigencia;
    this.weekDaysCheckboxGroup = Object.assign([], weekDayCheckboxGroup);

    this.showFooter = false;
  }

  ngOnInit() {
    this.initFormControls();
    this.getWorkingHours();
    this.getPaymentMethods();
    this.getProfessionalList();
    this.listaModalidade$ = this.modalidadeService.getList();

    if (this.item && this.item.id > 0) {
      this.dialogTitle = 'Renovar Matrícula';
    
      this.labelDataInicio = this.item.plano.vigencia.numeroMeses == 0 ? 'Data da aula/sessão *' :
      'Data de início das aulas/sessões *';

      this.planoAtual = this.item.plano;
      this.item.diaSemana = [];

      this.patchFormGroup();

      this.formGroup.get('modalidadeId').disable();

      this.getPaymentPlans();
      this.setAmountPlan();
      this.setPlanDiscountValue();
    }

    if (this.clienteAtual && this.clienteAtual.id > 0) {
      this.formGroup.controls.alunoId.setValue(this.clienteAtual.id, {});
      this.formGroup.controls.alunoId.disable();

      this.clients.push(this.clienteAtual);
    }

    this.changeDescontoSub = this.formGroup.get('desconto').valueChanges.subscribe(val => {
      this.setPlanDiscountValue();

      if (this.planHasValue() && !this.planoAtual.calcularMensaliadeNumeroAulas && (val != this.descontoPlano || this.formGroup.get('parcelas').value != this.parcelasPlano)) {
        this.planPeddingChange = true;
      } else {
        this.planPeddingChange = false;
      }
    });

    this.changeParcelasSub = this.formGroup.get('parcelas').valueChanges.subscribe(val => {
      this.setPlanDiscountValue();

      if (this.planHasValue() && !this.planoAtual.calcularMensaliadeNumeroAulas && (val != this.parcelasPlano || this.formGroup.get('desconto').value != this.descontoPlano)) {
        this.planPeddingChange = true;
      } else {
        this.planPeddingChange = false;
      }
    });

  }

  ngOnDestroy() {
    this.changeDescontoSub.unsubscribe();
    this.changeParcelasSub.unsubscribe();

    super.ngOnDestroy();
  }

  public initFormControls(): void {
    this.formGroup = new UntypedFormBuilder().group({
      matriculaOrigemId: [0],
      alunoId: [null, [Validators.required, Validators.min(1)]],
      modalidadeId: [0, [Validators.required, Validators.min(1)]],
      planoId: [0, [Validators.required, Validators.min(1)]],
      desconto: [0],
      parcelas: [0],
      horarioLivre: [false],
      horarioProfissionalDiferente: [false],
      geralHora: [0, [Validators.required, Validators.min(1), Validators.max(23)]],
      geralFuncionarioId: [0, [Validators.required, Validators.min(1)]],
      diaSemana: [[]],
      dom: [false],
      domHora: [0],
      domFuncionarioId: [0],
      seg: [false],
      segHora: [0],
      segFuncionarioId: [0],
      ter: [false],
      terHora: [0],
      terFuncionarioId: [0],
      qua: [false],
      quaHora: [0],
      quaFuncionarioId: [0],
      qui: [false],
      quiHora: [0],
      quiFuncionarioId: [0],
      sex: [false],
      sexHora: [0],
      sexFuncionarioId: [0],
      sab: [false],
      sabHora: [0],
      sabFuncionarioId: [0],
      dataInicio: ['', Validators.required],
      renovarAutomaticamente: [false],
      tipoPagamentoId: [1],
      mensalidades: new UntypedFormGroup({})
    });
  }

  private planHasValue(): boolean {
    return this.planoAtual.valor > 0;
  }

  async save() {
    if (!this.planHasValue && !this.canEnterThirdStep()) {
      this.onExitSecondStep();
      return;
    }

    if (this.planPeddingChange) {
      return;
    }

    this.submitted = true;

    if (this.planoAtual.valorTotalPlano < this.planoAtual.valorDescontoPlano) {
      return;
    }

    if (this.listaMensalidades.filter(el => el.modificado).length) {
      return;
    }

    this.blockui.start();
    
    const item = this.formGroup.getRawValue() as Matricula;
    item.dataInicio = new Date(item.dataInicio).toDateString();
    item.mensalidades = this.listaMensalidades;
    
    delete item['modalidadeId'];
    delete item['diaSemana'];
    delete item['parcelas'];
    delete item['tipoPagamentoId'];

    if (this.item.id > 0) {
      this.renewMatricula(item);
    } else {
      this.postMatricula(item);
    }
  }

  private postMatricula(item: Matricula): void {
    this.service.save(item)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.toastr.success('Matrícula criada com sucesso!');
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  private renewMatricula(item: Matricula) {
    this.service.renew(item)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.toastr.success('Matrícula renovada com sucesso!');
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message);
      }
    );

  }

  public getWorkingHours() {
    this.agendaService.listHour.subscribe(list => {
      this.workingHours = list;
    });
  }

  private getPaymentPlans(): void {
    const modalidadeId = Number(this.formGroup.get('modalidadeId').value);

    if (modalidadeId > 0) {
      this.planoService.getList(`modalidade/${modalidadeId}`).subscribe(list => this.listaPlanoModalidade = list);
    }
  }

  private getPaymentMethods(): void {
    this.service.paymentMethods().subscribe(
      paymentMethods => {
        this.listaFormaPagamento = paymentMethods;
      }
    );
  }

  private getProfessionalList(): void {
    this.professionalService.getList('agenda').subscribe(
      professionalList => {
        this.professionalList = professionalList;
      },
      error => {
        console.log(error.message, 'Error');
      }
    );
  }

  private searchClient(searchTerm: string): void {
    this.service.searchClient(searchTerm).subscribe(clientList => {
      this.searchClientLoading = false;

      if (clientList && clientList.length > 0) {
        this.clients.splice(0, this.clients.length);
        this.clients = clientList;
      }
    });
  }

  private invoicesCalculate(): void {
    this.calculatedInvoices = true;
    this.planPeddingChange = false;

    this.blockui.start();

    const args = {
      planoId: this.planoAtual.id,
      dataInicio: (<Date>this.formGroup.get('dataInicio').value).toDateString(),
      diasSemana: (<string[]>this.formGroup.get('diaSemana').value).join('|'),
      parcelas: Number(this.formGroup.get('parcelas').value),
      tipoPagamentoId: Number(this.formGroup.get('tipoPagamentoId').value),
      desconto: Number(this.formGroup.get('desconto').value),
      horarioLivre: this.formGroup.get('horarioLivre').value
    } as MensalidadeMatriculaArgs;

    this.service.invoicesCalculate(args)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      invoices => {
        this.formGroup.controls.mensalidades = new UntypedFormGroup({});

        invoices.forEach((el, ix) => {
          (<UntypedFormGroup>this.formGroup.get('mensalidades'))
          .addControl(`mensalidade_valor_${ix}`, new UntypedFormControl([el.valor]));
        });

        this.listaMensalidades = invoices;

        this.descontoPlano = args.desconto;
        this.parcelasPlano = args.parcelas;
      },
      error => {
        this.toastr.error(error.message);
        this.planPeddingChange = true;
      }
    );

  }

  public onSearchClient($event: any): void {
    const searchTerm = $event.term.toString();

    clearTimeout(this.searchTime);

    if (searchTerm.length < 3) {
      return;
    }

    this.searchClientLoading = true;

    this.searchTime = setTimeout(() => {
      this.searchClient(searchTerm);
    }, 1000);
  }

  public ngSelectChange(event: any): void {
    this.clienteAtual = Object.assign({}, event as Cliente);
  }

  public onChangeModalidade(): void {
    this.formGroup.get('planoId').setValue(0);

    this.getPaymentPlans();
  }

  public onChangePlanoPagamento(): void {
    const planoId = Number(this.formGroup.controls.planoId.value);

    this.calculatedInvoices = false;

    this.formGroup.get('horarioLivre').setValue(false);
    this.planPeddingChange = false;

    if (!this.item.plano) {
      this.item.plano = <Plano>{};
      this.item.plano.vigencia = <Vigencia>{};
    }

    const _self = this;
    const filterPlano = function (fn: Function) {
      const plano = Object.assign({}, _self.listaPlanoModalidade.find(v => v.id == planoId));
      _self.planoAtual = plano;
      _self.planoAtual.valorDescontoPlano = 0;
      fn(plano);
    };

    filterPlano((plano: Plano) => {
      this.item.plano = plano;

      this.setAmountPlan();

      this.labelDataInicio = this.item.plano.vigencia.numeroMeses == 0 ? 'Data da aula/sessão *' :
      'Data de início das aulas/sessões *';

      this.patchParcelasDescontoFormControl();

      if (plano.aulasSemana !== (<[string]>this.formGroup.get('diaSemana').value).length) {
        this.formGroup.get('diaSemana').setValue([]);
      }

      this.setValidatorsWeekdayFormControl();

    });
  }

  public onChangeWeekDay(): void {
    if (this.formGroup.get('diaSemana').invalid && !!this.formGroup.get('horarioProfissionalDiferente').value) {
      this.formGroup.get('horarioProfissionalDiferente').setValue(false);
      this.setStatusGeralHoraAndGeralFuncionario('enable');
    }

    this.weekDaysCheckboxGroup.forEach(el => {
      this.formGroup.get(el.id).setValue(false);
    });

    (this.formGroup.get('diaSemana').value as [string]).forEach(el => {
      this.formGroup.get(el).setValue(true);
    });

    if ((this.formGroup.get('diaSemana').value as [string]).length == this.planoAtual.aulasSemana) {
      this.weekDayControl.close();
    }
  }

  public onChangeHorarioLivre(): void {
    this.calculatedInvoices = false;
  }

  public onCheckDifferentTime(): void {
    const horarioDiferente = this.formGroup.controls.horarioProfissionalDiferente.value as boolean;

    if (!!horarioDiferente) {
      this.setStatusGeralHoraAndGeralFuncionario('disable');
    } else {
      this.setStatusGeralHoraAndGeralFuncionario('enable');
    }

    this.patchWeedaysControls(horarioDiferente);
  }

  private setAmountPlan(): void {
    const maxParcelas = this.planoAtual.vigencia.numeroMeses > 0 ? this.planoAtual.vigencia.numeroMeses : 1;
    this.planoAtual['valorTotalPlano'] = this.planoAtual.valor * maxParcelas;
  }

  private setPlanDiscountValue(): void {
    this.planoAtual.valorDescontoPlano = this.formGroup.get('desconto').value as number * this.formGroup.get('parcelas').value as number;
  }

  private patchWeedaysControls(differentTime: boolean): void {
    const selectedWeekdays = this.formGroup.get('diaSemana').value as [string];
    const generalTime = this.formGroup.get('geralHora').value;
    const generalProfessionalId = this.formGroup.get('geralFuncionarioId').value;

    this.weekDaysCheckboxGroup.forEach(el => {
      if (selectedWeekdays.indexOf(el.id) >= 0 && differentTime) {
        this.formGroup.get(el.controlHour.id).setValue(generalTime);
        this.formGroup.get(el.controlProfessional.id).setValue(generalProfessionalId);
      } else {
        this.formGroup.get(el.controlHour.id).setValue(0);
        this.formGroup.get(el.controlHour.id).setValue(0);
      }
    });
  }

  private setStatusGeralHoraAndGeralFuncionario(status: string): void {
    if (status == 'enable') {
      this.formGroup.get('geralHora').enable();
      this.formGroup.get('geralFuncionarioId').enable();
      return;
    }

    this.formGroup.get('geralHora').disable();
    this.formGroup.get('geralFuncionarioId').disable();
  }

  public onChangeInvoice(event: any, field: string, index: number): void {
    const DOMElement = field == 'tipoPagamentoId' ? <HTMLSelectElement>event.target : null;
    const value = field == 'tipoPagamentoId' ? Number(DOMElement.value) : event as Date;
    const originalDateValue = this.listaMensalidades[index].dataVencimento as Date;

    if (field == 'dataVencimento' && (value as Date).toDateString() == originalDateValue.toDateString()) {
      return;
    }

    if (this.listaMensalidades.length == 1 || index == this.listaMensalidades.length - 1) {
      this.changeInvoiceList(field, value, index);
      return;
    }

    this.modalService.show(ChangeListItemComponent, {
      class: 'modal-sm modal-centered shadow-xl',
      initialState: {
        dialogTitle: 'Editar mensalidade'
      }
    });

    const modalHiddenSub = this.modalService.onHidden.subscribe(result => {
      if (result == 'changeItem') {
        this.changeInvoiceList(field, value, index);
      } else if (field == 'tipoPagamentoId' && result == 'changeItemAndNext') {
        this.listaMensalidades.forEach((el, ix) => {
          if (ix >= index) {
            this.changeInvoiceList(field, value, ix);
          }
        });
      } else if (field == 'dataVencimento' && result == 'changeItemAndNext') {
        this.changeInvoiceList('dataVencimento', value, index);
        this.listaMensalidades[index]['alterarProximos'] = true;

        this.changeInvoicesDate();
      } else {
        if (field == 'tipoPagamentoId') {
          DOMElement.value = this.listaMensalidades[index]['tipoPagamentoId'].toString();
        } else {
          this.listaMensalidades[index]['dataVencimento'] = new Date(originalDateValue.toDateString());
        }
      }

      modalHiddenSub.unsubscribe();
    });
  }

  public onClickRecalculate(): void {
    if (this.planoAtual.valorTotalPlano < this.planoAtual.valorDescontoPlano) {
      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-sm modal-centered shadow-xl',
      backdrop: 'static',
      initialState: {
        contentText: 'Todas as modificações anteriores nas mensalidades serão perdidas!',
        contentQuestion: 'Deseja continuar?',
        dialogTitle: 'Aplicar alterações'
      }
    });

    const modalHiddenSub = this.modalService.onHidden.subscribe(result => {
      if (typeof result == 'string' && result == 'true') {
        this.invoicesCalculate();
      } else {
        this.formGroup.get('parcelas').setValue(this.listaMensalidades.length);
      }

      modalHiddenSub.unsubscribe();
    });
  }

  private changeInvoiceList(field: string, value: any, index: number): void {
    this.listaMensalidades[index][field] = value;

    if (field == 'tipoPagamentoId') {
      this.listaMensalidades[index]['tipoPagamentoNome'] = this.listaFormaPagamento.find(el => el.id == value)['nome'];
    }
  }

  private changeInvoicesDate(): void {
    this.blockui.start();

    this.service.updateInvoiceDates(this.listaMensalidades)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      list => {
        this.listaMensalidades = list;
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  public onKeyUpInvoiceValue(event, index): void {
    const invoice = this.listaMensalidades.find((el, ix) => ix == index);
    const invoiceFormValue: number = (<UntypedFormControl>this.formGroup.controls['mensalidades']['controls'][`mensalidade_valor_${index}`]).value;

    invoice['modificado'] = (invoice.valor != invoiceFormValue);

    this.submitted = false;
  }

  public onClickChangeInvoiceValue(event: Event, index: number): void {
    if (this.planPeddingChange) {
      return;
    }

    const curInvoice = this.listaMensalidades[index];

    if (curInvoice.valorFixo && !curInvoice.modificado && this.listaMensalidades.filter((el, ix) => el.modificado && ix != index).length) {
      this.submitted = true;
      return;
    }

    const args = {
      mensalidades: [...JSON.parse(JSON.stringify(this.listaMensalidades))],
      valorTotalPlano: this.planoAtual.valorTotalPlano,
      desconto: this.formGroup.get('desconto').value
    } as ArgsUpdateInvoiceValues;

    args.mensalidades
    .map((el, ix) => {
      el.valor = el.modificado ?
      (<UntypedFormControl>this.formGroup.controls['mensalidades']['controls'][`mensalidade_valor_${ix}`]).value : el.valor;

      if (el.valorFixo && ix == index && el.valor == curInvoice.valor) {
        el.valorFixo = !el.valorFixo;
      } else {
        el.valorFixo = (el.modificado || el.valorFixo);
      }

      delete el.modificado;
    });

    const fixedInvoices = args.mensalidades.filter(el => el.valorFixo);

    if (fixedInvoices.length == args.mensalidades.length) {
      this.toastr.warning('Não é possível fixar o valor em todas as parcelas!');
      this.pathFormgroupMensalidades();
      return;
    }

    this.changeInvoiceValue(args);
  }

  private changeInvoiceValue(args: ArgsUpdateInvoiceValues): void {
    this.blockui.start();

    this.service.updateInvoiceValues(args)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      lst => {
        this.listaMensalidades = lst;
        this.pathFormgroupMensalidades();
      },
      error => {
        this.pathFormgroupMensalidades();
        this.toastr.error(error.message);
      }
    );
  }

  public onExitFirstStep(): void {
    const controlsFirstStep = ['alunoId', 'modalidadeId', 'planoId'];

    controlsFirstStep.forEach(el => {
      this.formGroup.get(el).markAsTouched();
    });

    this.matricularWizard.goToNextStep();
  }

  public canEnterSecondStep(): boolean {
    if (this.formGroup.get('alunoId').invalid || this.formGroup.get('modalidadeId').invalid || this.formGroup.get('planoId').invalid) {
      return false;
    }

    return true;
  }

  public onExitSecondStep(): void {
    const controlsSecondStep = ['dataInicio', 'geralHora', 'geralFuncionarioId', 'diaSemana'];

    controlsSecondStep.forEach(el => {
      this.formGroup.get(el).markAsTouched();
    });

    this.matricularWizard.goToNextStep();
  }

  public canEnterThirdStep(): boolean {
    const horarioDiferente = this.formGroup.get('horarioProfissionalDiferente').value;

    if (this.formGroup.get('dataInicio').invalid) {
      return false;
    }

    if (this.formGroup.get('horarioLivre').value) {
      return true;
    }

    if (!horarioDiferente && (this.formGroup.get('geralHora').invalid || this.formGroup.get('geralFuncionarioId').invalid)) {
      return false;
    }

    if (!horarioDiferente && this.formGroup.get('diaSemana').invalid && this.planoAtual.vigencia.numeroMeses != 0) {
      return false;
    }

    return true;
  }

  public enterThirdStep(event: any): void {
    if (this.calculatedInvoices) {
      return;
    }

    this.invoicesCalculate();
  }

  public showCheckboxHorarioDiferente(): boolean {
    if (this.formGroup.controls.horarioLivre.value) {
      return false;
    }

    if (this.item.plano?.vigencia.numeroMeses != 0 &&
      this.item.plano?.aulasSemana > 1) {
        return true;
    }
  }

  protected async patchFormGroup() {
    this.item.dataInicio = new Date((this.item.dataFimDT as string).slice(0, -1)) as Date;
    this.item.dataInicio.setDate(this.item.dataInicio.getDate() + 1);
    this.item.matriculaOrigemId = this.item.id;
    this.item.mensalidades = [];

    this.weekDaysCheckboxGroup.forEach(el => {
      if (!!this.item[el.id]) {
        this.item.diaSemana.push(el.id);
      }
    });

    super.patchFormGroup();

    this.patchParcelasDescontoFormControl();

    this.setValidatorsWeekdayFormControl();
  }

  private patchParcelasDescontoFormControl(): void {
    let parcelas  = undefined;
    if (this.item.descontoTotal != this.item.desconto) {
      parcelas = this.item.descontoTotal / this.item.desconto;
    }

    this.formGroup.get('parcelas').setValue(parcelas || this.planoAtual.vigencia.numeroMeses || 1);
    this.formGroup.get('desconto').setValue(this.item.desconto || 0);
  }

  private pathFormgroupMensalidades(): void {
    this.listaMensalidades.forEach((el, ix) => {
      this.formGroup.controls['mensalidades']['controls'][`mensalidade_valor_${ix}`].setValue(el.valor);
    });
  }

  private setValidatorsWeekdayFormControl(): void {
    this.messageInvalidWeekday =
     this.planoAtual.aulasSemana == 1 ? 'Selecione 1 dia da semana.' : `Selecione ${this.planoAtual.aulasSemana} dias da semana.`;
    this.formGroup.get('diaSemana').setValidators([Validators.required, Validators.minLength(this.planoAtual.aulasSemana)]);
    this.formGroup.get('diaSemana').updateValueAndValidity();
  }

  public resetDescontoEParcelas(): void {
    this.formGroup.get('desconto').setValue(this.descontoPlano);
    this.formGroup.get('parcelas').reset(this.parcelasPlano);
  }

  public dateMask(event: any) {
    const v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }

}
