import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clear-layout',
  template: '<block-ui><router-outlet></router-outlet></block-ui>'
})
export class ClearLayoutComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
