import { Injectable, Injector } from '@angular/core';

import { IAgendaDetalhes } from '../models';

import { CoreService } from '../../../shared/core.service';

@Injectable()
export class AlunosExperimentaisService extends CoreService<IAgendaDetalhes> {
  protected url = `${this.api.url}agenda/experimental/`;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
