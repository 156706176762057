import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  DefaultLayoutComponent,
  SimpleLayoutComponent,
  AuthLayoutComponent,
  ClearLayoutComponent} from './layouts';

import { AuthGuard } from './views/auth/guards';
import { AvisosNoticiasComponent } from './views/avisos-noticias/avisos-noticias.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'aula-online-aluno',
    component: ClearLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/aula-online-aluno/aula-online-aluno.module').then(m => m.AulaOnlineAlunoModule)
      }
    ]
  },
  {
    path: 'print',
    component: SimpleLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'reports',
        runGuardsAndResolvers: 'always',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./views/print-report/print-report.module').then(m => m.PrintReportModule)
      },
      {
        path: '**',
        component: SimpleLayoutComponent
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'avisos',
        component: AvisosNoticiasComponent,
        loadChildren: () => import('./views/avisos-noticias/avisos-noticias.module').then(m => m.AvisosNoticiasModule)
      },
      {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'agenda',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/agenda/agenda.module').then(m => m.AgendaModule)
      },
      {
        path: 'aula-online',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/aula-online/aula-online.module').then(m => m.AulaOnlineModule)
      },
      {
        path: 'avaliacao',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/avaliacao/avaliacao.module').then(m => m.AvaliacaoModule)
      },
      {
        path: 'configuracoes',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule)
      },
      {
        path: 'matriculas',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/matriculas/matriculas.module').then(m => m.MatriculasModule)
      },
      {
        path:  'financeiro',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/financeiro/financeiro.module').then(m => m.FinanceiroModule)
      },
      {
        path: 'cliente',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/cliente/cliente.module').then(m => m.ClienteModule),
      },
      {
        path: 'profissional',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/professional/professional.module').then(m => m.ProfessionalModule)
      },
      {
        path: 'perfil',
        loadChildren: () => import('./views/professional/perfil-component/perfil-routing.module').then(m => m.PerfilRoutingModule)
      },
      {
        path: 'cargo',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/cargo/cargo.module').then(m => m.CargoModule)
      },
      {
        path: 'modalidade',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/modalidades/modalidades.module').then(m => m.ModalidadesModule)
      },
      {
        path: 'plano',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/plano/plano.module').then(m => m.PlanoModule)
      },
      {
        path: 'controle-acesso',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'parametros',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/param/param.module').then(m => m.ParamModule)
      },
      {
        path: 'feriado',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/feriado/feriado.module').then(m => m.FeriadoModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'inicio'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
