import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { ClienteService } from '../../cliente.service';

@Component({
  selector: 'app-cliente-base-form',
  templateUrl: './cliente-base-form.component.html',
  styleUrls: ['./cliente-base-form.component.scss']
})
export class ClienteBaseFormComponent implements OnInit, OnDestroy  {
  @BlockUI() blockui: NgBlockUI;
  @Input() public parent: any;

  private changeCEPSub: Subscription = new Subscription();

  constructor(
    private toastr: ToastrService,
    private service: ClienteService
  ) { }

  ngOnInit(): void {
    this.changeCEPSub = this.parent.formGroup.controls['endereco']['controls']['cep'].valueChanges.subscribe(value => {
      if (value && value.toString().length == 8) {
        this.getAddress(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.changeCEPSub.unsubscribe();
  }

  getAddress(cep?: string): void {
    if (!cep) {
      return;
    }

    this.blockui.start();

    this.service.getAddressByCEP(cep)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      address => {
        for (var key in address) {
          const value = this.parent.formGroup.controls['endereco']['controls'][key].value;
          
          if (value === null || value.trim() === "") {
            this.parent.formGroup.controls['endereco']['controls'][key].patchValue(address[key]);
          }
        }
      },
      () => {
        this.toastr.error('Erro ao buscar o CEP', 'Error');
      }
    );
  }

  public onFocusOutNome() {
    const nome: string = this.parent.formGroup.controls.nome.value;
    const nomeCurto: string = this.parent.formGroup.controls.nomeCurto.value;

    if (!nome === null && nome.length > 0 && (nomeCurto === null || nomeCurto.length == 0)) {
      const nomeSeparado = nome.split(' ');
      let apelido = '';

      let cont = 0;
      for (let i = 0; i < nomeSeparado.length; i++) {
        if (nomeSeparado[i].length > 2 && cont < 2) {
          apelido = apelido.concat(' ', nomeSeparado[i]);
          cont++;
        }
      }

      this.parent.formGroup.controls.nomeCurto.patchValue(apelido.trim());
    }
  }

  public dateMask(event: any) {
    const v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }

}
