import { Component, Injector } from '@angular/core';

import { MensalidadeFormBaseDirective } from './mensalidade-form-base.directive';

import { MensalidadeAlunoService } from '../../services';

@Component({
  selector: 'app-mensalidade-form',
  templateUrl: './mensalidade-form-layout.component.html',
  styleUrls: ['./mensalidade-form-layout.component.scss']
})
export class MensalidadeFormAlunoComponent extends MensalidadeFormBaseDirective {
  public static resourceName = 'MensalidadeAluno';

  constructor(
    protected service: MensalidadeAlunoService,
    protected injector: Injector
  ) {
    super(injector, service);

    this.getPaymentMethods();
  }

  private getPaymentMethods(): void {
    this.service.paymentMethods().subscribe(
      paymentMethods => {
        this.listaFormaPagamento = paymentMethods;
      }
    );
  }

  protected printInvoice() {
    super.printInvoice('MensalidadeAluno');
  }

}
